export const getToken = () => {
  // const token = sessionStorage.getItem("SUPER");
  const token = localStorage.getItem("SUPER");

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
