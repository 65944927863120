import template1 from "../assets/image/templates/01.png";
import template2 from "../assets/image/templates/02.png";
import template3 from "../assets/image/templates/03.png";
import template4 from "../assets/image/templates/04.png";
import template5 from "../assets/image/templates/05.png";
import template6 from "../assets/image/templates/06.png";
import template7 from "../assets/image/templates/07.png";
import template8 from "../assets/image/templates/08.png";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import HandshakeRoundedIcon from "@mui/icons-material/HandshakeRounded";

import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";

export const SUPER_MENU = [
  {
    key: "menu-0-2",
    value: "고객사 관리",
    path: "/company",
    icon: <HandshakeRoundedIcon />,
  },
  // {
  //   key: "menu-0-3",
  //   value: "응시자 Q&A",
  //   path: "/qna",
  //   icon: <QuestionAnswerRoundedIcon />,
  // },
  {
    key: "menu-0-4",
    value: "약관관리",
    path: "/terms",
    icon: <AccountBalanceRoundedIcon />,
  },
  {
    key: "menu-0-5",
    value: "레포트관리",
    path: "/report",
    icon: <ArticleRoundedIcon />,
  },
];
// const companyIdx = useRecoilValue(companyIdxAtom);
export const HR_MENU = [
  // {
  //   key: "menu-0-1",
  //   value: "채용그룹 관리",
  //   path: `/acghr_hr/${companyIdx}/group`,
  //   icon: <ApartmentRoundedIcon />,
  // },
  {
    key: "menu-0-4",
    value: "HR 담당자관리",
    path: "/acghr_hr/manage",
    icon: <PeopleAltRoundedIcon />,
  },
];

export const TEST_MANAGE = [
  { key: "menu-1-1", value: "적성검사" },
  { key: "menu-1-2", value: "인성검사" },
  { key: "menu-1-3", value: "검사세트 관리" },
  { key: "menu-1-4", value: "레포트 관리" },
];

// ================== QNA ==================

export const QnA_DETAIL = [
  { label: "카테고리", column: "category" },
  { label: "등록일", column: "createdAt" },
  { label: "답변작성일", column: "updatedAt" },
  { label: "답변여부", column: "replySuccessYN" },
  { label: "담당자", column: "adminName" },
  { label: "응시자명", column: "testerName" },
  { label: "응시자 이메일", column: "testerEmail" },
  { label: "답변형식", column: "replyUse" },
  { label: "질문내용", column: "text" },
  { label: "답변내용", column: "replyText" },
];

export const QnA_REPLY_MODAL = [
  { label: "카테고리", column: "category" },
  { label: "등록일", column: "createdAt" },
  { label: "응시자명", column: "testerName" },
  { label: "응시자 이메일", column: "testerEmail" },
  { label: "응시자 연락처", column: "testerCell" },
  { label: "질문내용", column: "text" },
  { label: "답변방식", column: "replyUse" },
  { label: "답변내용", column: "replyText" },
];

export const QnA_LIST_SEARCH = [
  { label: "내용", value: "text" },
  { label: "담당자", value: "adminName" },
  { label: "응시자 이름", value: "testerName" },
  { label: "응시자 이메일", value: "testerEmail" },
  { label: "응시자 전화번호", value: "testerCell" },
];

export const QnA_CATEGORY = [
  { value: "LOGIN", label: "로그인/접속" },
  { value: "AROUND", label: "주변기기" },
  { value: "PROGRAM", label: "프로그램" },
  { value: "ETC", label: "기타" },
];

export const QnA_IS_ANSWERED = [
  { label: "답변 미완료", value: "N" },
  { label: "답변 완료", value: "Y" },
];

export const QnA_REPLY_USE = [
  { label: "이메일", value: "email" },
  { label: "SMS", value: "sms" },
];

export const QnA_TABLE_ROW = [
  { label: "No", size: 30 },
  { label: "카테고리", size: 90 },
  { label: "내용", size: 190 },
  { label: "이메일", size: 85 },
  { label: "이름", size: 70 },
  { label: "전화번호", size: 140 },
  { label: "담당자", size: 70 },
  { label: "등록일", size: 50 },
  { label: "답변여부", size: 90 },
];

export const QnA_DELETE_EVENT_REASON = [
  { value: "질문 입출력 시스템 오류" },
  { value: "욕설 및 비방의 내용" },
  { value: "직접 입력" },
];

// ================== GroupList ==================

export const GROUP_SUMMARY_CARD = [
  { value: "companyName", label: "회사명" },
  { value: "groupName", label: "공고명" },
  { value: "normName", label: "검사명" },
  { value: "examSAt", label: "검사일" },
  { value: "total", label: "검사인원" },
];

export const GROUP_CONFIRM_STATUS = [
  { label: "전체", value: "TOTAL" },
  { label: "확정", value: "Y" },
  { label: "미확정", value: "N" },
];

export const GROUP_STATUS = [
  { label: "전체", value: "TOTAL" },
  { label: "사전검사 대기", value: "PRE_WAIT" },
  { label: "사전검사 진행", value: "PRE_INPROGRESS" },
  { label: "본검사 대기", value: "EXAM_WAIT" },
  { label: "본검사 진행", value: "EXAM_INPROGRESS" },
  { label: "본검사 종료", value: "EXAM_DONE" },
];

export const GROUP_LIST_SEARCH = [
  { value: "groupName", label: "공고명" },
  // { value: "userName", label: "담당자" },
  { value: "normName", label: "검사세트" },
];

export const GROUP_ROOM_LIST = [
  { size: 60, label: "화상룸" },
  { size: 70, label: "감독관" },
  { size: 78, label: "감독관 번호" },
  { size: 70, label: "인증키" },
  { size: 60, label: "총원" },
  { size: 67, label: "사전점검" },
  { size: 50, label: "완료" },
  { size: 58, label: "진행중" },
  { size: 58, label: "미완료" },
];
export const GROUP_ROOM_TESTER_LIST = [
  { size: 60, label: "이름" },
  { size: 80, label: "생년월일" },
  { size: 100, label: "전화번호" },
  { size: 57, label: "사전점검" },
  { size: 57, label: "해외응시" },
  { size: 57, label: "출석여부" },
  { size: 110, label: "최종 로그인 시간" },
  { size: 90, label: "응시현황" },
  { size: 60, label: "통화상태" },
  { size: 30, label: "더보기" },
];

export const GROUP_ROOM_LIST_VIDEO = [
  { size: 60, label: "룸번호" },
  { size: 60, label: "이름" },
  { size: 80, label: "생년월일" },
  { size: 100, label: "전화번호" },
  { size: 60, label: "사전점검" },
  { size: 60, label: "해외응시" },
  { size: 60, label: "출석여부" },
  { size: 90, label: "최종 로그인 시간" },
  { size: 60, label: "응시현황" },
  { size: 30, label: "더보기" },
];
export const GROUP_ROOM_LIST_NO_VIDEO = [
  { size: 60, label: "이름" },
  { size: 80, label: "생년월일" },
  { size: 100, label: "전화번호" },
  { size: 60, label: "해외응시" },
  { size: 90, label: "최종 로그인 시간" },
  { size: 60, label: "응시현황" },
  { size: 30, label: "더보기" },
];
export const LOGIN_LOG_DATA = [
  { size: 60, label: "인증키" },
  { size: 80, label: "접속환경" },
  { size: 100, label: "최근 로그인 시간" },
  { size: 50, label: "IP주소" },
  { size: 60, label: "네트워크 환경" },
  { size: 60, label: "로그인 여부" },
];
export const VIDEO_LOG_DATA = [
  { size: 60, label: "분류" },
  { size: 80, label: "녹화시간" },
];

export const CREATE_GROUP_FORM_LABEL = [
  { label: "공고명" },
  { label: "검사명" },
  { label: "화상감독여부" },
  { label: "화상감독 최대인원" },
  { label: "화면공유 사용여부" },
  { label: "모바일 화면공유 여부" },
  { label: "사전검사 여부" },
  { label: "사전점검 시작일" },
  { label: "사전점검 종료일" },
  { label: "본검사 시작일" },
  { label: "본검사 종료일" },
];

export const GROUP_TESTER_MORE_LIST = [
  { index: 1, label: "응시자수정" },
  { index: 2, label: "응시자 진행상태 수정" },
  { index: 5, label: "신분증확인" },
  { index: 6, label: "접속로그 확인" },
  { index: 7, label: "채팅로그 확인" },
  { index: 8, label: "녹화영상 확인" },
];
export const GROUP_TESTERLIST_MORE_LIST = [
  { index: 1, label: "응시자수정" },
  { index: 2, label: "응시자 진행상태 수정" },
  { index: 4, label: "접속로그 확인" },
];

// ================== CompanyList ==================

export const COMPANY_LIST_MAIN = [
  { label: "진행상황", size: 50 },
  { label: "고객사명", size: 280 },
  { label: "대표번호", size: 150 },
  { label: "담당자", size: 80 },
  { label: "검사세트", size: 80 },
  { label: "UI 템플릿", size: 80 },
  { label: "수정", size: 80 },
  { label: "채팅템플릿", size: 80 },
  { label: "등록일", size: 100 },
];
export const COMPANY_USER_LIST = [
  { label: "권한" },
  { label: "이름" },
  { label: "연락처" },
  { label: "최근 로그인 시간" },
];

export const COMPANY_DELETE_EVENT_REASON = [
  { value: "입출력 시스템 오류" },
  { value: "계약철회" },
  { value: "직접 입력" },
];

export const TEMPLATE_IMG = [
  { src: template1, name: "템플릿1" },
  { src: template2, name: "템플릿2" },
  { src: template3, name: "템플릿3" },
  { src: template4, name: "템플릿4" },
  { src: template5, name: "템플릿5" },
  { src: template6, name: "템플릿6" },
  { src: template7, name: "템플릿7" },
  { src: template8, name: "템플릿8" },
];

export const TERMS_COL = [
  { size: 45, label: "NO" },
  { size: 400, label: "약관목록" },
  { size: 70, label: "삭제" },
  { size: 90, label: "등록일" },
];

export const TERMS_DELETE_EVENT_REASON = [
  { value: "그냥" },
  { value: "안씀" },
  { value: "철회" },
];

export const HR_MANAGE_COL = [
  { size: 30, label: "권한" },
  { size: 30, label: "이름" },
  { size: 100, label: "휴대전화 번호" },
  { size: 100, label: "직통번호" },
  { size: 100, label: "이메일" },
  { size: 30, label: "삭제" },
  { size: 100, label: "최근 로그인 시간" },
];

export const HR_MANAGE_WAITING_COL = [
  { size: 50, label: "권한" },
  { size: 50, label: "이름" },
  { size: 100, label: "휴대전화 번호" },
  { size: 100, label: "직통번호" },
  // { size: 100, label: "비밀번호" },
  { size: 100, label: "이메일" },
  { size: 30, label: "수정" },
  { size: 30, label: "삭제" },
];

export const SEARCH_USER = [
  { size: 40, label: "회사이름" },
  { size: 40, label: "응시자이름" },
  { size: 40, label: "생년월일" },
  { size: 40, label: "전화번호" },
  { size: 40, label: "공고이름" },
  { size: 40, label: "검사시작 일시" },
  { size: 40, label: "상태" },
];

export const SEARCH_MENU = [
  { value: "groupName", label: "본부/부문" },
  { value: "userName", label: "공고명" },
  { value: "normName", label: "검사구분" },
];

export const SEARCH_MENU_STATS = [
  { value: "partName", label: "본부/부문" },
  { value: "groupName", label: "공고명" },
];

export const STATS_TABLE = [
  { size: 10, label: "No" },
  { size: 45, label: "진행현황" },
  { size: 50, label: "회사명" },
  { size: 50, label: "본부/부문" },
  { size: 130, label: "공고명" },
  { size: 60, label: "검사 시작" },
  { size: 60, label: "검사 종료" },
  { size: 40, label: "검사유형" },
  { size: 50, label: "지원 분야" },
  { size: 20, label: "화상" },
  { size: 20, label: "총원" },
  { size: 20, label: "완료" },
];

export const STATS_SUMMARY_TABLE = [
  { label: "No" },
  { label: "회사명" },
  { label: "참여율" },
  { label: "완료" },
  { label: "총원" },
];

export const STATS_MANAGER_LIST = [
  { label: "No" },
  // { label: "권한" },
  { label: "담당자이름" },
  { label: "휴대전화 번호" },
  { label: "직통번호" },
  { label: "이메일" },
  { label: "최근 로그인 시간" },
];

export const STATS_TESTER_LIST = [
  { label: "No" },
  { label: "ID" },
  { label: "응시자명" },
  { label: "휴대전화뻔호" },
  { label: "본검사" },
];

export const STATS_DETAIL_PAGE_TABLE = [
  { label: "No" },
  { label: "ID" },
  { label: "응시자명" },
  { label: "휴대전화 번호" },
  { label: "검사 완료여부" },
];
