import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import { dateFormatTime } from "utils/formatter/dateFormat";

const GroupCard = ({ selectedItem = {}, status }) => {
  const {
    examEAt = "",
    examSAt = "",
    groupName = "",
    normName = "",
    preExamEAt = "",
    preExamSAt = "",
    preExamUseYN = "",
  } = selectedItem;

  return (
    <Grid item container xs={12} columnGap={2} justifyContent="space-around">
      <Grid
        bgcolor={"white"}
        borderRadius={2}
        item
        xs
        container
        columnGap={6}
        // py={2}
        alignItems="center"
        justifyContent={"center"}
      >
        <Box>
          <Grid item container flexDirection={"column"} alignItems="center">
            <Typography lineHeight={2} textAlign={"center"}>
              공고명
            </Typography>
            <Typography fontWeight={700}>
              {status ? <Skeleton variant="rounded" width={150} /> : groupName}
            </Typography>
          </Grid>
        </Box>
      </Grid>

      <Grid
        bgcolor={"white"}
        borderRadius={2}
        item
        xs
        container
        columnGap={6}
        justifyContent="center"
        p={2}
      >
        {preExamUseYN === "N" ? null : (
          <Grid item>
            <Typography lineHeight={2} textAlign={"center"}>
              사전점검 일정
            </Typography>
            <Typography fontWeight={700}>
              {status ? (
                <Skeleton variant="rounded" width={150} />
              ) : (
                `${dateFormatTime(preExamSAt)} ~ ${dateFormatTime(preExamEAt)}`
              )}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography lineHeight={2} textAlign={"center"}>
            본 검사 일정
          </Typography>
          <Typography fontWeight={700}>
            {status ? (
              <Skeleton variant="rounded" width={150} />
            ) : (
              `${dateFormatTime(examSAt)} ~ ${dateFormatTime(examEAt)}`
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupCard;
