export const bizCodeString = (code) => {
  const stringCode = code.toString();
  switch (stringCode) {
    case "0":
      return "공통";
    case "1":
      return "경영지원";
    case "2":
      return "경영지원(사업관리/재무)";
    case "3":
      return "경영지원(인사)";
    case "4":
      return "경영지원(전략)";
    case "5":
      return "영업";
    case "6":
      return "마케팅";
    case "7":
      return "디자인";
    case "8":
      return "연구개발";
    case "9":
      return "IT";
    case "10":
      return "건설/개발";
    case "11":
      return "콘텐츠";
    case "12":
      return "서비스";
    case "13":
      return "물류";
    case "14":
      return "제조";
    default:
      return "공통";
  }
};
