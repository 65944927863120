import { useCallback } from "react";

// Style
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LOGO from "assets/image/logo/ACG_CI-그레이2.png";

// Route
import { Link, useNavigate } from "react-router-dom";

// Recoil
import { useRecoilValue } from "recoil";
import { isNavOpen } from "recoil/store";

// Components
import Temp from "components/pages/acg_admin/tempHighAuthMenu/Temp";
import { confirmModalConfig } from "components/templates/modalConfig";
import MenuComponent from "components/UI/menu/MenuComponent";
import { DrawerHeader } from "components/UI/nav/DrawerHeader";
import NavMenuList from "components/UI/nav/NavMenuList";

// Utils
import { jwtDecoder } from "utils/jwtDecoder";

// Enum
import { menuListTitle_ACG } from "enums/navMenu";
import { USER_GRADE } from "enums/userGrade";
import { useTokenTimer } from "hooks/useTokenTimer";
import useMutationHook from "hooks/useMutationHook";
import Swal from "sweetalert2";
import { questionIcon } from "assets/icon/Icon_modal";

export default function NavSuper() {
  const navigate = useNavigate();
  const isOpen = useRecoilValue(isNavOpen);

  const info = jwtDecoder(localStorage.getItem("SUPER"));

  const { mutate: logout } = useMutationHook("logout");

  const handleLogout = useCallback(() => {
    Swal.fire(
      confirmModalConfig({
        text: "로그아웃 하시겠습니까?",
        title: "로그아웃",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed)
        logout(
          {},
          {
            onSuccess: () => {
              navigate("/");
              localStorage.removeItem("SUPER");
            },
          }
        );
    });
  }, []);

  const { countdownDays, countdownHours, countdownMinutes, countdownSeconds } = useTokenTimer();

  return (
    <>
      <Drawer
        variant="persistent"
        anchor={"left"}
        open={isOpen}
        sx={{
          width: 260,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 260,
            boxSizing: "border-box",
            border: "none",
            padding: "0px 20px 0px 30px",
            backgroundColor: "#f8f9fa",
          },
          position: "relative",
        }}
      >
        <Link to="/company">
          <DrawerHeader logo={LOGO} />
        </Link>
        <Box mt={3} backgroundColor={"white"} borderRadius={2}>
          <Grid container alignItems={"center"} my={2} justifyContent="center">
            <MenuComponent icon={<MenuRoundedIcon />} list={[{ title: "개인정보수정" }, { title: "로그아웃", function: handleLogout }]} />
            <Typography sx={{ wordBreak: "keep-all" }} fontWeight={700}>
              {info.adminName} 님
            </Typography>
            <Typography>, 안녕하세요 😀</Typography>
          </Grid>
          <Divider />
          <NavMenuList menuListTitle={menuListTitle_ACG} />
        </Box>

        {(info?.adminGrade === USER_GRADE.HIGH_ADMIN || info?.adminGrade === USER_GRADE.TOP_ADMIN) && <Temp />}
        <Typography sx={{ position: "absolute", bottom: 120 }}>
          접속 유효시간 : {`${countdownDays}일 ${countdownHours}시간 ${countdownMinutes}분 ${countdownSeconds}초`}
        </Typography>
      </Drawer>
    </>
  );
}
