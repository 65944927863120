import React from "react";
import { Button, Dialog, Grid, TextField, Typography } from "@mui/material";
import useMutationHook from "hooks/useMutationHook";
import { useState } from "react";
import { MESSAGE_UPDATE_PASSWORD } from "enums/modalMessage";
import { modalAtom } from "recoil/store";
import { useRecoilState } from "recoil";

//* 비밀번호 변경 컴포넌트
const UpdateUserPassword = () => {
  const { mutate: updateUser } = useMutationHook("updatePassword", "HR");
  const [modalOn, setModalOn] = useRecoilState(modalAtom);
  const [passwordInfo, setPasswordInfo] = useState({
    prevPassword: "",
    newPassword: "",
  });

  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const handlePrevPassword = (e) => {
    setPasswordInfo((prev) => ({ ...prev, prevPassword: e.target.value }));
  };

  const handleNewPassword = (e) => {
    setPasswordInfo((prev) => ({ ...prev, newPassword: e.target.value }));
  };

  const handleNewPasswordConfirm = (e) => {
    setNewPasswordConfirm(e.target.value);
  };

  const [warningMessage, setWarningMessage] = useState("");

  const validateEqualPassword = (passwordInfo) => {
    if (newPasswordConfirm !== passwordInfo.newPassword)
      return setWarningMessage("비밀번호가 다릅니다.");
    else return setWarningMessage("");
  };

  const resetValue = () => {
    setPasswordInfo({ prevPassword: "", newPassword: "" });
    setNewPasswordConfirm("");
    setWarningMessage("");
  };

  const submit = () => {
    setModalOn({
      modalState: !modalOn.modalState,
      subTitle: MESSAGE_UPDATE_PASSWORD,
      type: "confirm",
      title: "비밀번호 변경",
      status: "warning",
      modalFunc: () => {
        updateUser(
          {
            userPass: passwordInfo.prevPassword,
            newPass: passwordInfo.newPassword,
          },
          {
            onSuccess: () => {
              setModalOn({
                modalState: !modalOn.modalState,
                title: "비밀번호 변경",
                type: "alert",
                subTitle: "비밀번호 변경이 완료되었습니다.",
                status: "success",
                modalFunc: () => {
                  resetValue();
                },
              });
            },
          }
        );
      },
    });
  };

  return (
    <Grid container flexDirection={"column"}>
      <Typography fontWeight={700}>비밀번호 변경</Typography>
      <Grid container p={2} rowGap={1} flexDirection="column">
        <Grid item container flexDirection={"column"}>
          <Typography
            variant="body2"
            sx={{
              "&:after": {
                content: '"*"',
                color: "red",
              },
            }}
          >
            기존 비밀번호
          </Typography>
          <TextField
            size="small"
            type={"password"}
            value={passwordInfo?.prevPassword}
            onChange={handlePrevPassword}
          />
        </Grid>
        <Grid item container flexDirection={"column"}>
          <Typography
            variant="body2"
            sx={{
              "&:after": {
                content: '"*"',
                color: "red",
              },
            }}
          >
            새 비밀번호 입력
          </Typography>
          <TextField
            size="small"
            type={"password"}
            value={passwordInfo?.newPassword}
            onChange={handleNewPassword}
          />
        </Grid>
        <Grid item container flexDirection={"column"}>
          <Typography
            variant="body2"
            sx={{
              "&:after": {
                content: '"*"',
                color: "red",
              },
            }}
          >
            새 비밀번호 확인
          </Typography>
          <TextField
            size="small"
            type={"password"}
            value={newPasswordConfirm}
            onChange={handleNewPasswordConfirm}
            onBlur={() => validateEqualPassword(passwordInfo)}
            error={warningMessage !== "" && true}
            helperText={warningMessage !== "" && warningMessage}
          />
        </Grid>
        <Grid item container justifyContent={"flex-end"}>
          <Button variant="contained" onClick={submit}>
            변경하기
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpdateUserPassword;
