import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import dayjs from "dayjs";
import "./settingTimeCalendar.css";
import { CalendarDays, HelpCircle } from "lucide-react";

const today = new Date();
const SettingTime = ({ onClose }) => {
  const [settingTime, setSettingTime] = useState([
    new Date(today.setHours(9, 0, 0, 0)),
    new Date(today.setHours(17, 0, 0, 0)),
  ]);
  const handleSettingTime = (value) => {
    setSettingTime(value);
  };
  return (
    <>
      <DialogTitle fontWeight={700}> 점검시간</DialogTitle>
      <DialogContent>
        <Stack direction="row" columnGap={1} alignItems="center" mb={0.8}>
          <Typography>점검시간 설정</Typography>
          <Tooltip
            title="점검시간에는 로그인이 제한됩니다."
            placement="top-start"
            arrow
          >
            <HelpCircle size={17} strokeWidth={1.3} />
          </Tooltip>
        </Stack>

        <DateTimeRangePicker
          disableClock
          formatDay={(locale, date) => dayjs(date).format("D")}
          calendarType="US"
          locale="ko-KO" // 한글버전
          onChange={handleSettingTime}
          value={settingTime}
          className="preExamCalendar"
          calendarClassName="openCalendar"
          calendarIcon={<CalendarDays size={17} strokeWidth={1.3} />}
          clearIcon={null}
        />
      </DialogContent>

      <DialogActions sx={{ pr: 2, pb: 2 }}>
        <Stack direction="row" columnGap={1}>
          <Button variant="contained" onClick={onClose}>
            확인
          </Button>
          <Button variant="outlined" onClick={onClose}>
            취소
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

export default SettingTime;
