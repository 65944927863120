import React, { useRef, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useRecoilState } from "recoil";
import { modalAtom } from "recoil/store";

import useGetQueryhr from "hooks/useGetQueryhr";

import { tableHeader, tableWrapper } from "themes/theme";
import { STATS_DETAIL_PAGE_TABLE } from "enums/menuList";
import { calcRowNum } from "utils/calcRowNum";
import { examStatusTag } from "utils/tagRender";
import TopTitle from "components/templates/topTitle/TopTitle";
import { TableContainer } from "@mui/material";
import { Download } from "lucide-react";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import Swal from "sweetalert2";
import { questionIcon } from "assets/icon/Icon_modal";
import { confirmModalConfig } from "components/templates/modalConfig";
import useMutationHook from "hooks/useMutationHook";

const DetailStatsPage = () => {
  const { companyName, partName, groupName } = useLocation().state;

  const { id, company } = useParams();
  const [paramValues, setParamValues] = useState({
    pageNo: 1,
    perPage: 20,
  });

  const { data, pageInfo, isLoading, isFetching, isError, refetch } =
    useGetQueryhr("getDetail_CJ", { paramValues, groupIdx: Number(id) });

  const { mutate } = useMutationHook("downloadTesterListStats", "HR");

  const testerNameRef = useRef();

  const submitSearch = () => {
    setParamValues((prev) => ({
      ...prev,
      testerInfo: testerNameRef.current.value,
    }));
  };

  const handleTesterDownloadModal = () => {
    Swal.fire(
      confirmModalConfig({
        text: "응시자 목록을 다운로드하시겠습니까?",
        title: "응시자 리스트 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          {
            groupIdx: Number(id),
            testerInfo: testerNameRef.current.value || null,
          },
          {
            onSuccess: (res) => {
              window.open(`https://${res.data.data}`);
            },
          }
        );
      }
    });
  };
  return (
    <>
      <Box
        width="100%"
        height="100%"
        borderRadius={2}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <TopTitle
          list={[
            {
              title: "검사진행 현황",
              url: `/acghr_hr/${company}/stats`,
              current: false,
            },
            {
              title: partName
                ? `${companyName} / ${partName || "-"} / ${groupName} 채용 공고`
                : `${companyName} / ${groupName} 채용 공고`,
              url: `/acghr_hr/${company}/stats/${id}`,
              current: true,
            },
          ]}
        />

        <Grid
          container
          columnGap={1}
          alignItems="center"
          justifyContent="space-between"
          flexWrap="nowrap"
          mt={4}
        >
          <Grid item container columnGap={1} alignItems="center">
            <TextField
              size="small"
              inputRef={testerNameRef}
              placeholder={`응시자 이름을 입력하세요 `}
            />
            <Button
              sx={{ height: "100%" }}
              variant="contained"
              onClick={submitSearch}
            >
              검색
            </Button>
            <Typography>{`총 ${pageInfo.total} 명`}</Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ width: 120 }}
              variant="contained"
              onClick={handleTesterDownloadModal}
              startIcon={<Download size={13} strokeWidth={1.2} />}
            >
              응시자 다운로드
            </Button>
          </Grid>
        </Grid>

        <Paper
          sx={{
            ...tableWrapper,
            overflowY: "hidden",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: 2,
            // p: 2,
            rowGap: 2,
          }}
          variant="outlined"
        >
          {data && data.data.length < 1 ? (
            <Box
              height={"100%"}
              display="flex"
              alignItems={"center"}
              justifyContent="center"
              py={10}
            >
              <EmptyPlaceholder />
            </Box>
          ) : (
            <TableContainer sx={{ height: "calc(95% - 20px)" }}>
              <Table stickyHeader sx={tableHeader} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="none" align="center" sx={{ width: 30 }}>
                      <Checkbox
                        size="small"
                        style={{ color: "#637381" }}
                        //   onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    {STATS_DETAIL_PAGE_TABLE.map((item) => {
                      return (
                        <TableCell
                          padding="none"
                          key={item.label}
                          // sx={{ width: item.size, fontWeight: "bold" }}
                          align="center"
                        >
                          {item.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.data.map((item, index) => (
                      <TableRow
                        sx={{
                          "&": { height: 45, cursor: "pointer" },
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={index}
                      >
                        <TableCell padding="none" align="center">
                          <Checkbox
                            size="small"
                            color="primary"
                            sx={{ padding: 0 }}
                            //   onClick={(event) => handleCheckClick(event, row)}
                            //   checked={isSelected(row.groupIdx)}
                          />
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {calcRowNum(index, pageInfo, 20)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {item.testerId || "-"}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {item.testerName}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {item.testerCell || "-"}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          <Grid width={"40%"} margin="auto">
                            {examStatusTag(item.examStatus)}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Grid container alignItems={"center"} justifyContent="center">
            <Pagination
              count={pageInfo.totalPage}
              page={pageInfo.pageNo}
              onChange={(_, page) => {
                setParamValues((prev) => ({
                  ...prev,
                  pageNo: page,
                }));
              }}
            />
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default DetailStatsPage;
