import React, { Suspense, useState } from "react";
import { Button, Dialog, Grid, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import VerifyCellNum from "./VerifyCellNum";
import { HRcompanyInfo } from "recoil/store";
import { useRecoilValue } from "recoil";

//* 핸드폰변경 컴포넌트
const UpdateCellNum = () => {
  const companyInfo = useRecoilValue(HRcompanyInfo);
  const [changeCellNumModalOn, setChangeCellNumModalOn] = useState(false);
  const handleChangeCellNum = () => {
    setChangeCellNumModalOn(!changeCellNumModalOn);
  };

  return (
    <Grid container flexDirection={"column"}>
      <Typography fontWeight={700}>핸드폰번호 변경</Typography>
      <Grid container p={2} rowGap={1} flexDirection="column">
        <Grid item container flexDirection={"column"} justifyContent={"center"}>
          <Typography
            variant="body2"
            sx={{
              "&:after": {
                content: '"*"',
                color: "red",
              },
            }}
          >
            핸드폰번호
          </Typography>

          <Grid
            item
            container
            columnGap={1}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Grid item xs>
              <TextField
                fullWidth
                size="small"
                disabled
                // placeholder={companyInfo.cellNum}
                // onChange={(e) => onChange(autoHyphen(e.target.value))}
                value={companyInfo?.userCell}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleChangeCellNum}>
                수정
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Suspense fallback={"loading.."}>
        <Dialog
          open={changeCellNumModalOn}
          onClose={handleChangeCellNum}
          fullWidth
          maxWidth="xs"
        >
          <VerifyCellNum onClose={handleChangeCellNum} />
        </Dialog>
      </Suspense>
    </Grid>
  );
};

export default UpdateCellNum;
