import React, { useState, useCallback, useRef } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";

import { useParams } from "react-router-dom";

import { modalAtom, practicePageDetailAtom } from "recoil/store";
import { useRecoilState, useRecoilValue } from "recoil";

import { useQueryClient } from "@tanstack/react-query";
import useMutationHook from "hooks/useMutationHook";

import { ReactComponent as Upload } from "assets/icon/uploadIcon.svg";
import Swal from "sweetalert2";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";

/* 
//* 조건
likertYN = "Y"
ipsativeYN = "Y"
*/

const CreateOnePersonalPractice = ({
  practiceType,
  pageNo,
  onClose,
  isEmpty = false,
}) => {
  const { id } = useParams();
  const practicePageDetail = useRecoilValue(practicePageDetailAtom);
  const queryClient = useQueryClient();
  const { maxScale, questionSetCnt, likertYN, ipsativeYN } = practiceType;
  const questionExNoRef = useRef([]);
  const buttonNamePopUpRef = useRef();
  const [imgPreview, setImgPreview] = useState("");
  const { buttonNamePopUp, popUpImg } =
    practicePageDetail?.practiceList?.length > 0 &&
    practicePageDetail?.practiceList[0];
  //* 해설 파일 업로드
  const { mutate: upload_AnswerImg } = useMutationHook("upload_AnswerImg");

  //* 예제 등록 Mutation
  const { mutate } = useMutationHook("createPracticeDetail");

  const [practiceTitle, setPracticeTitle] = useState({
    questionNo: 1,
    questionText: "",
    questionSubUseYN: likertYN === "Y" && ipsativeYN === "N" ? "N" : "Y",
    questionExLayout: 1,
    buttonNamePopUp: "",
    popUpImg: "",
  });

  const questionTextRef = useRef();
  const questionSubTextRef = useRef();
  const questionNoRef = useRef();

  // const handlePracticeTitleInfo = (e, index) => {
  //   console.log("index: ", index);
  //   const { value, name } = e.target;
  //   // setPracticeTitle((prev) => {
  //   //   console.log("prev: ", prev);
  //   //   prev[index][name] = value;
  //   //   return prev;
  //   // });

  //   setPracticeTitle((prev) => ({ ...prev, [name]: value }));
  // };

  //* Mutation 후 이벤트
  const alertResult = useCallback((key, key2) => {
    onClose();
    queryClient.invalidateQueries({
      queryKey: [key],
    });
    queryClient.invalidateQueries({
      queryKey: [key2],
    });
  }, []);

  const handleUpload = (e) => {
    Swal.fire(
      confirmModalConfig({
        text: "해설 이미지 업로드",
        title: "이미지 업로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        upload_AnswerImg(
          {
            practiceIdx: Number(id),
            questionNo: questionNoRef.current.value,
            answerImg: e.target.files[0],
          },
          {
            onSuccess: (res) => {
              Swal.fire(
                alertModalConfig({
                  text: "이미지 업로드가 완료되었습니다.",
                  title: "해설 이미지 업로드",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) setImgPreview(res.data.data);
              });
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  text: "이미지 업로드를 실패하였습니다.",
                  title: "해설 이미지 업로드",
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
      }
    });
  };
  const handleSubmit = () => {
    const submitValues = {
      practiceTitleRegistInfo: {
        questionNo: Number(questionNoRef.current.value),
        questionText: questionTextRef.current.value,
        questionSubUseYN: likertYN === "Y" && ipsativeYN === "N" ? "N" : "Y",
        questionSubText:
          likertYN === "Y" && ipsativeYN === "N"
            ? null
            : questionSubTextRef.current.value,

        questionExLayout: 3,
        buttonNamePopUp: buttonNamePopUpRef.current?.value || buttonNamePopUp,
        popUpImg: imgPreview === "" ? popUpImg : imgPreview,
      },
      practiceExRegistList: questionExNoRef.current.map((item, index) => {
        return {
          questionExNo: index + 1,
          questionExText: item.value,
        };
      }),
      pageNo: pageNo,
      likertYN: likertYN,
      maxScale: maxScale,
      ipsativeYN: ipsativeYN,
      questionSetCnt: ipsativeYN === "Y" ? questionSetCnt : null,
    };
    console.log("submitValues: ", submitValues);

    Swal.fire(
      confirmModalConfig({
        text: "예제를 등록하시겠습니까?",
        title: "예제등록",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          { values: submitValues, practiceIdx: Number(id) },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "예제 등록이 완료되었습니다.",
                  title: "예제 등록 완료",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed)
                  alertResult("getPracticeQnA", "practiceDetail");
              });
            },
          }
        );
      }
    });

    console.log("submitValues: ", submitValues);
  };

  return (
    <>
      <DialogContent>
        <Grid container direction="column" rowGap={2}>
          {(practicePageDetail.practiceList === undefined ||
            practicePageDetail.practiceList.length < 1) &&
            pageNo === 1 && (
              <Grid item xs container columnGap={1} direction="column">
                <Typography fontWeight={700}>응답척도</Typography>
                <Grid container justifyContent="space-between" columnGap={1}>
                  {[...Array(maxScale)].map((item, index) => {
                    return (
                      <Grid item xs>
                        <TextField
                          size="small"
                          fullWidth
                          placeholder="척도명을 입력하세요."
                          inputRef={(el) =>
                            (questionExNoRef.current[index] = el)
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          <Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mb={1}
            >
              <Typography fontWeight={700}>문항내용</Typography>
              {/* <IconButton
                size="small"
                onClick={handleAddrow}
                sx={{
                  background: "#c6cedb",
                }}
              >
                <AddRoundedIcon />
              </IconButton> */}
            </Grid>
            <Grid container direction="column" rowGap={1}>
              {/* {practiceTitle.map((item, index) => {
                return (
                  <Grid key={index} container columnGap={1}>
                    <Grid item xs={0.7}>
                      <TextField
                        size="small"
                        placeholder="번호"
                        name="questionSubText"
                        onChange={(e) => handlePracticeTitleInfo(e, index)}
                        sx={{ "&>div>input": { textAlign: "center" } }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="문항내용을 입력하세요."
                        name="questionText"
                        onChange={(e) => handlePracticeTitleInfo(e, index)}
                      />
                    </Grid>
                  </Grid>
                );
              })} */}
              <Grid container columnGap={1}>
                <Grid item xs={0.7}>
                  <TextField
                    size="small"
                    placeholder="번호"
                    name="questionNo"
                    inputRef={questionNoRef}
                    // onChange={handlePracticeTitleInfo}
                    sx={{ "&>div>input": { textAlign: "center" } }}
                  />
                </Grid>
                {likertYN === "Y" && ipsativeYN === "N" ? null : (
                  <Grid item xs={0.7}>
                    <TextField
                      size="small"
                      placeholder="하위 문항번호"
                      name="questionSubText"
                      inputRef={questionSubTextRef}
                      // onChange={handlePracticeTitleInfo}
                      sx={{ "&>div>input": { textAlign: "center" } }}
                    />
                  </Grid>
                )}
                <Grid item xs>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="문항내용을 입력하세요."
                    name="questionText"
                    inputRef={questionTextRef}
                    // onChange={handlePracticeTitleInfo}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Typography fontWeight={700}>검사 안내 버튼</Typography>
            <TextField
              size="small"
              sx={{ maxWidth: "20%" }}
              placeholder="버튼명을 입력하세요."
              inputRef={buttonNamePopUpRef}
            />
          </Grid>
          <Grid item container direction="column">
            <Typography fontWeight={700}>정답 및 해설 이미지</Typography>
            <Grid
              py={2}
              container
              justifyContent="center"
              flexDirection={"column"}
              rowGap={1}
              alignItems="center"
            >
              {imgPreview === "" ? (
                <>
                  <Upload />
                  <Typography
                    color="#777"
                    textAlign="center"
                    sx={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}
                  >
                    {`파일을 직접 업로드하거나 \n드래그하여 업로드 해주세요 (최대 10MB)`}
                  </Typography>
                  <Button variant="outlined" size="small" component="label">
                    업로드
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleUpload}
                    />
                  </Button>
                </>
              ) : (
                <Box
                  position="relative"
                  component="label"
                  sx={{
                    cursor: "pointer",
                    "&:hover > .image": {
                      opacity: 0.3,
                    },
                    "&:hover > .middle": {
                      opacity: 1,
                    },
                  }}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleUpload}
                  />
                  <Box
                    className="image"
                    component="img"
                    src={imgPreview}
                    display="block"
                    width="100%"
                    height="auto"
                    sx={{
                      transition: ".5s ease",
                      opacity: 1,
                      backfaceVisibility: "hidden",
                    }}
                  />

                  <Box
                    sx={{
                      transition: ".5s ease",
                      opacity: 0,
                      transform: "translate(-50%, -50%)",
                    }}
                    className="middle"
                    position="absolute"
                    top="50%"
                    left="50%"
                    textAlign="center"
                  >
                    <Box class="text">
                      <IconButton
                        size="large"
                        component="label"
                        variant="contained"
                        sx={{
                          background: "#7c828d",
                          color: "white",
                        }}
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          // onChange={handleUpload}
                        />
                        <DriveFolderUploadRoundedIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button variant={"contained"} onClick={handleSubmit}>
          확인
        </Button>
        <Button variant={"outlined"}>취소</Button>
      </DialogActions>
    </>
  );
};

export default CreateOnePersonalPractice;
