import jwt_decode from "jwt-decode";

export const jwtDecoder = (input) => {
  try {
    const data = jwt_decode(input);
    return data;
    // valid token format
  } catch (error) {
    // invalid token format
    // console.log("error: " + JSON.stringify(sessionStorage));
  }
};
