function leftPad(value) {
  if (value >= 10) {
    return value;
  }

  return `0${value}`;
}
const WEEKDAY = ["일", "월", "화", "수", "목", "금", "토"];

export function dateIncludeTime(source, delimiter = ".") {
  if (source === null) return "-";
  else {
    const dateSource = new Date(source);
    const year = dateSource.getFullYear();
    const month = leftPad(dateSource.getMonth() + 1);
    const day = leftPad(dateSource.getDate());
    const weekDay = WEEKDAY[dateSource.getDay()];

    let hours = ("0" + dateSource.getHours()).slice(-2);
    let minutes = ("0" + dateSource.getMinutes()).slice(-2);

    return `${year}.${month}.${day}(${weekDay}) ${hours}시 ${minutes}분`;
  }
}

export function dateIncludeTimeQnA(source, delimiter = ".") {
  const year = source.getFullYear();
  const month = leftPad(source.getMonth() + 1);
  const day = leftPad(source.getDate());
  let hours = ("0" + source.getHours()).slice(-2);
  let minutes = ("0" + source.getMinutes()).slice(-2);

  return `${year}.${month}.${day} / ${hours} : ${minutes}`;
}

export function toStringByFormatting(source, delimiter = ".") {
  const year = source.getFullYear();
  const month = leftPad(source.getMonth() + 1);
  const day = leftPad(source.getDate());
  const weekDay = WEEKDAY[source.getDay()];
  return `${year}.${month}.${day} (${weekDay})`;
}

// 2021-01-01

export function dashDelimiter(source, delimiter = "-") {
  const year = source?.getFullYear();
  const month = leftPad(source?.getMonth() + 1);
  const day = leftPad(source?.getDate());

  return [year, month, day].join(delimiter);
}

export const dateFormat = (input) => (input === null ? "-" : toStringByFormatting(new Date(input)));

export const dateFormatTime = (input) => {
  if (input === null) return "-";
  let time = new Date(input);

  let hours = ("0" + time.getHours()).slice(-2);
  let minutes = ("0" + time.getMinutes()).slice(-2);

  let year = time.getFullYear();
  let month = ("0" + (time.getMonth() + 1)).slice(-2);
  let day = ("0" + time.getDate()).slice(-2);

  // let dateString = year + "년 " + month + "월 " + day + "일";
  let dateString = month + "월 " + day + "일";

  // let timeString = hours + "시 " + minutes + "분";
  let timeString = hours + "시 " + minutes + "분";

  return dateString + " " + "( " + timeString + " )";
};

export const dateFormatTimeAIChart = (input) => {
  if (input === null) return "-";
  let time = new Date(input);

  let hours = ("0" + time.getHours()).slice(-2);
  let minutes = ("0" + time.getMinutes()).slice(-2);
  let sec = ("0" + time.getSeconds()).slice(-2);

  let year = time.getFullYear();
  let month = ("0" + (time.getMonth() + 1)).slice(-2);
  let day = ("0" + time.getDate()).slice(-2);

  // let dateString = year + "년 " + month + "월 " + day + "일";
  let dateString = month + "월 " + day + "일 ";

  // let timeString = hours + "시 " + minutes + "분";
  let timeString = hours + "시 " + minutes + "분 " + sec + "초";

  return dateString + timeString;
};

export const todayFlag = (input) => (new Date(input).toDateString() === new Date().toDateString() ? true : false);

export const chatLogTime = (input) => {
  const date = new Date(input);
  let nowHour = date.getHours();
  let nowMt = date.getMinutes();
  if (nowMt < 10) nowMt = "0" + nowMt;
  if (nowHour <= 12 && nowHour >= 6) {
    return "오전 " + nowHour + ":" + nowMt;
  } else if (nowHour >= 12 && nowHour < 22) {
    return "오후 " + (Number(nowHour) - 12) + ":" + nowMt;
  }
};

export const loginEnableTimeFormat = (input) => {
  const date = new Date(input);

  // const hour = new Date(date.setHours(date.getHours()));

  const startMinutes = new Date(date.setMinutes(date.getMinutes() - 40));
  const endMinutes = new Date(date.setMinutes(date.getMinutes() + 20));

  const start = ("0" + startMinutes.getHours()).slice(-2) + "시 " + ("0" + startMinutes.getMinutes()).slice(-2) + "분";
  const end = ("0" + endMinutes.getHours()).slice(-2) + "시 " + ("0" + endMinutes.getMinutes()).slice(-2) + "분";

  return `${start} ~ ${end}`;
};

export const timeFormat = (input) => {
  let month = input.getMonth() + 1;
  let day = input.getDate();

  month = month >= 10 ? month : "0" + month;
  day = day >= 10 ? day : "0" + day;

  return input.getFullYear() + "-" + month + "-" + day;
};

export const roomTesterListTimeFormat = (input) => {
  if (input === null) return "-";
  let time = new Date(input);

  let hours = ("0" + time.getHours()).slice(-2);
  let minutes = ("0" + time.getMinutes()).slice(-2);

  let year = time.getFullYear();
  let month = ("0" + (time.getMonth() + 1)).slice(-2);
  let day = ("0" + time.getDate()).slice(-2);
  const weekDay = WEEKDAY[time.getDay()];
  // let dateString = year + "년 " + month + "월 " + day + "일";
  let dateString = year + "-" + month + "-" + day + "(" + weekDay + ")";

  // let timeString = hours + "시 " + minutes + "분";
  let timeString = hours + "시 " + minutes + "분";

  return dateString + " " + "( " + timeString + " )";
};

export function readableTime(seconds) {
  if (seconds < 61) {
    return "00:" + addZero(seconds);
  }
  // sec
  var hours = Math.floor(seconds / 3600);
  var mins = Math.floor((seconds - hours * 3600) / 60);
  var secs = seconds - hours * 3600 - mins * 60;

  if (hours < 1) {
    return addZero(mins) + "분 " + addZero(secs) + "초";
  } else {
    return addZero(hours) + "시간" + addZero(mins) + " 분" + addZero(secs) + " 초";
  }

  function addZero(num) {
    return (num < 10 ? "0" : "") + num;
  }
}

export function addZero(num) {
  return (num < 10 ? "0" : "") + num;
}

export function readableTimeMinutesSeconds(seconds, type = "mix") {
  // sec
  var hours = Math.floor(seconds / 3600);
  var mins = Math.floor((seconds - hours * 3600) / 60);
  var secs = seconds - hours * 3600 - mins * 60;

  if (type === "seconds") {
    return addZero(secs);
  } else if (type === "minutes") {
    return addZero(mins);
  } else if (type === "mix") {
    return `${addZero(mins)}분 ${addZero(secs)}초`;
  }

  function addZero(num) {
    return (num < 10 ? "0" : "") + num;
  }
}
