import styled from "@emotion/styled";

export const DrawerHeader = styled("div")(({ theme, logo }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(3.5),
  backgroundImage: `url(${logo})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
