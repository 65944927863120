import React, { useCallback, useEffect, useState, useRef } from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import useGetQuery from "hooks/useGetQuery";
import useMutationHook from "hooks/useMutationHook";
import { useQueryClient } from "@tanstack/react-query";

const AuthKey = ({ onClose }) => {
  const queryClient = useQueryClient();
  //* QUERY
  const { data, pageInfo, isLoading, isFetching, isError, refetch } =
    useGetQuery("authKeyInfo", {}, true, false);

  //* 수정 Mutation
  const { mutate: updateAuthKeyType } = useMutationHook("updateAuthKeyType");
  const { mutate: updateAuthKey } = useMutationHook("updateAuthKey");

  const [isEditMode, setIsEditMode] = useState({
    key: false,
    platform: false,
  });
  const handleEditMode = (_, name) => {
    setIsEditMode((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  const masterAuthKeyRef = useRef();
  const [platformAuthKeyType, setPlatformAuthKeyType] = useState("");

  useEffect(() => {
    data && setPlatformAuthKeyType(data.platformAuthKeyType);
  }, [data]);

  const handlePlatformAuthKeyType = (e) => {
    updateAuthKeyType(
      { authKeyType: e.target.value },
      {
        onSuccess: (res) => {
          setPlatformAuthKeyType(e.target.value);
          handleEditMode(null, "platform");
          alertResult("authKeyInfo");
        },
      }
    );
  };

  const handlePlatformAuthKey = (e) => {
    updateAuthKey(
      { masterAuthKey: masterAuthKeyRef.current.value },
      {
        onSuccess: (res) => {
          handleEditMode(null, "key");
          alertResult("authKeyInfo");
        },
      }
    );
  };

  return (
    <>
      <DialogTitle fontWeight={700}>인증키 설정</DialogTitle>
      <DialogContent>
        <Typography fontSize={"1.15rem"} mb={2}>
          인증키 정보
        </Typography>

        <Grid container flexDirection={"column"} rowGap={1}>
          <Stack direction={"row"} alignItems="center" columnGap={1}>
            <Typography fontSize={"1rem"}>인증키 발송타입 :</Typography>
            {isEditMode.platform ? (
              <Stack direction={"row"} columnGap={1} alignItems="center">
                <Select
                  size="small"
                  value={platformAuthKeyType}
                  onChange={handlePlatformAuthKeyType}
                >
                  <MenuItem key="SMS" value="SMS">
                    SMS
                  </MenuItem>
                  <MenuItem key="TALK" value="TALK">
                    TALK
                  </MenuItem>
                </Select>
                <IconButton
                  variant="outlined"
                  size="small"
                  sx={{
                    background: "#ffecec",
                    color: "#ff4444",
                    "&>svg": {
                      width: "1.2rem",
                      height: "1.2rem",
                    },
                  }}
                  onClick={(e) => handleEditMode(e, "platform")}
                >
                  <ClearRoundedIcon />
                </IconButton>
              </Stack>
            ) : (
              <Stack direction={"row"} columnGap={1} alignItems="center">
                <Typography fontSize={"1rem"} fontWeight={700}>
                  {data?.platformAuthKeyType}
                </Typography>
                <IconButton
                  variant="outlined"
                  size="small"
                  onClick={(e) => handleEditMode(e, "platform")}
                >
                  <SettingsOutlinedIcon />
                </IconButton>
              </Stack>
            )}
          </Stack>

          <Stack direction={"row"} alignItems="center" columnGap={1}>
            <Typography fontSize={"1rem"}>인증키 :</Typography>
            {isEditMode.key ? (
              <Stack direction={"row"} columnGap={1} alignItems="center">
                <Tooltip title="6자리의 숫자를 입력해주세요." arrow>
                  <Box component={"span"}>
                    <TextField
                      size="small"
                      inputRef={masterAuthKeyRef}
                      defaultValue={data?.masterAuthKey}
                    />
                  </Box>
                </Tooltip>
                <IconButton
                  variant="outlined"
                  size="small"
                  onClick={(e) => handleEditMode(e, "key")}
                  sx={{
                    background: "#ffecec",
                    color: "#ff4444",
                    "&>svg": {
                      width: "1.2rem",
                      height: "1.2rem",
                    },
                  }}
                >
                  <ClearRoundedIcon />
                </IconButton>
                <IconButton
                  variant="outlined"
                  size="small"
                  onClick={handlePlatformAuthKey}
                  sx={{
                    background: "#e0ffe2",
                    color: "#00a50b",
                    "&>svg": {
                      width: "1.2rem",
                      height: "1.2rem",
                    },
                  }}
                >
                  <CheckRoundedIcon />
                </IconButton>
              </Stack>
            ) : (
              <Stack direction={"row"} columnGap={1} alignItems="center">
                <Typography fontSize={"1rem"} fontWeight={700}>
                  {data?.masterAuthKey}
                </Typography>
                <IconButton
                  variant="outlined"
                  size="small"
                  onClick={(e) => handleEditMode(e, "key")}
                >
                  <SettingsOutlinedIcon />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pr: 2, pb: 2 }}>
        <Stack direction="row" columnGap={1}>
          <Button variant="outlined" onClick={onClose}>
            취소
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

export default AuthKey;
