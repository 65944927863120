import React from "react";

import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import { dateFormat } from "utils/formatter/dateFormat";
import CreateOneAptitudePractice from "./CreateOneAptitudePractice";
import CreateOnePersonalPractice from "./CreateOnePersonalPractice";

const CreatePracticeModal = ({
  pageNo,
  onClose,
  practiceType,
  isEmpty = false,
}) => {
  const { ipsativeYN, likertYN, maxScale, questionSetCnt } = practiceType;

  const testTypeModule = (onClose, pageNo) => {
    if (ipsativeYN === "N" && likertYN === "N")
      return <CreateOneAptitudePractice onClose={onClose} pageNo={pageNo} />;
    else
      return (
        <CreateOnePersonalPractice
          onClose={onClose}
          pageNo={pageNo}
          practiceType={practiceType}
          isEmpty={isEmpty}
        />
      );
  };

  return (
    <>
      <DialogTitle
        variant="h5"
        fontWeight="bold"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        예제문항등록
        <Typography component="span">
          {`등록일 : ${dateFormat(new Date())}`}
        </Typography>
      </DialogTitle>
      {testTypeModule(onClose, pageNo)}
    </>
  );
};

export default CreatePracticeModal;
