import DesignServicesRoundedIcon from "@mui/icons-material/DesignServicesRounded";

import {
  Building,
  CandlestickChart,
  ClipboardCheck,
  ClipboardList,
  Dot,
  MessagesSquare,
  ScrollText,
  Search,
  UserSquare,
} from "lucide-react";

const company = window.location.pathname.split("/")[2];

export const menuListTitle_ACG = [
  {
    depth1Title: "검사/운영",
    sub: [
      {
        depth2Title: "고객사 관리",
        icon: <Building size={16} strokeWidth={1.8} />,
        path: "/company",
      },
      {
        depth2Title: "약관관리",
        icon: <ScrollText size={16} strokeWidth={1.8} />,
        path: "/terms",
      },
      {
        depth2Title: "레포트관리",
        icon: <CandlestickChart size={16} strokeWidth={1.8} />,
        path: "/report",
      },
    ],
  },
  {
    depth1Title: "예제 및 문항관리",
    sub: [
      {
        depth2Title: "예제관리",
        icon: <Dot size={20} strokeWidth={1.8} />,
        path: "/practice",
      },
      {
        depth2Title: "문항관리",
        icon: <Dot size={20} strokeWidth={1.8} />,
        path: "/question",
      },
    ],
  },
  {
    depth1Title: "과목관리",
    sub: [
      {
        depth2Title: "과목관리",
        icon: <Dot size={16} strokeWidth={1.8} />,
        path: "/subject",
      },
    ],
  },
];

export const menuListTitle_HR = [
  {
    depth1Title: "검사/운영",
    sub: [
      {
        depth2Title: "채용그룹 관리",
        icon: <ClipboardList size={16} strokeWidth={1.8} />,
        path: `/acghr_hr/${company}/group`,
      },
      {
        depth2Title: "HR담당자 관리",
        icon: <UserSquare size={16} strokeWidth={1.8} />,
        path: `/acghr_hr/${company}/manager`,
      },
      {
        depth2Title: "응시자 Q&A",
        icon: <MessagesSquare size={16} strokeWidth={1.8} />,
        path: `/acghr_hr/${company}/qna`,
      },
      {
        depth2Title: "응시자 검색",
        icon: <Search size={16} strokeWidth={1.8} />,
        path: `/acghr_hr/${company}/searchTester`,
      },
    ],
  },
];
export const menuListTitle_HR_client = [
  {
    depth1Title: "검사/운영",
    sub: [
      {
        depth2Title: "채용그룹 관리",
        icon: <ClipboardList size={16} strokeWidth={1.8} />,
        path: `/acghr_hr/${company}/group`,
      },
      {
        depth2Title: "HR담당자 관리",
        icon: <UserSquare size={16} strokeWidth={1.8} />,
        path: `/acghr_hr/${company}/manager`,
      },
    ],
  },
];

export const menuListTitle_STATS = [
  {
    depth1Title: "검사/운영",
    sub: [
      {
        depth2Title: "실시 현황",
        icon: <ClipboardCheck size={16} strokeWidth={1.8} />,
        path: `/acghr_hr/${company}/stats`,
      },
      {
        depth2Title: "담당자 관리",
        icon: <UserSquare size={16} strokeWidth={1.8} />,
        path: `/acghr_hr/${company}/stats/manager`,
      },
    ],
  },
];

export const PILOT_MENU = [
  {
    key: "menu-2-4",
    value: "테스트관리",
    path: "/pilot",
    icon: <DesignServicesRoundedIcon color="primary" />,
  },
];
