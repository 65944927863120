import { Box, Button, Dialog, Menu, MenuItem } from "@mui/material";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import React, { Suspense, useState } from "react";
import AuthKey from "./AuthKey";
import SettingTime from "./SettingTime";

const Temp = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [modal, setModal] = useState(false);
  const handleTesterVideoLogModal = (_, reason) => {
    setAnchorEl(null);
    if (reason === "backdropClick" || reason === undefined) setModal(!modal);
  };

  const [settingTimeModalOn, setSettingTimeModalOn] = useState(false);

  const handleSettingTimeModal = () => {
    handleClose();
    setSettingTimeModalOn((prev) => !prev);
  };
  return (
    <Box position={"absolute"} left={30} bottom={80}>
      <Button variant="contained" onClick={handleClick}>
        플랫폼 관리
      </Button>
      <Menu
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleTesterVideoLogModal}>인증키 설정</MenuItem>
        <MenuItem onClick={handleClose}>메세지 전송</MenuItem>
        <MenuItem onClick={handleSettingTimeModal}>점검시간 설정</MenuItem>
      </Menu>

      <Dialog
        open={modal}
        onClose={handleTesterVideoLogModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <AuthKey onClose={handleTesterVideoLogModal} />
        </Suspense>
      </Dialog>
      <Dialog
        open={settingTimeModalOn}
        onClose={handleSettingTimeModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <SettingTime onClose={handleSettingTimeModal} />
        </Suspense>
      </Dialog>
    </Box>
  );
};

export default Temp;
