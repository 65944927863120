import axios from "axios";
import { SUB_URI, URI } from "../services/api/APIs";
import { getToken } from "./getToken";

export const getHandler = (flag, param) => {
  const token = getToken();
  // const submit = param.queryKey[1]?.search || {};
  let submit;
  const paramsWithToken = {
    ...token,
    ...{ params: { ...{ pageNo: param.queryKey[1]?.page }, ...submit } },
    ...{ timeout: 2000 },
  };
  let index;
  switch (flag) {
    //! 수정필요
    case "groupListSummary":
      return axios.get(`${URI}/user/groupManage/summary`, {
        ...paramsWithToken,
      });
    case "qnaListSummary":
      return axios.get(`${URI}/admin/qnaList/summary`, token);
    case "groupList":
      return axios.get(`${URI}/admin/groupList`, token);

    case "getRemovedItem":
      return axios.get(`${URI}/admin/qnaList/tempDelete`, {
        ...paramsWithToken,
      });

    case "testerList":
      return axios.get(`${URI}/admin/groupList/${index}/testerList`, {
        ...paramsWithToken,
      });

    case "roomList":
      index = param.queryKey[1]?.index;
      submit = param.queryKey[1]?.search || {};
      return axios.get(`${URI}/admin/groupList/${index}/roomList`, {
        ...token,
        ...{
          params: {
            pageNo: param.queryKey[1]?.page,
          },
        },
      });
    case "roomTesterList":
      index = param.queryKey[1]?.index;
      return axios.get(`${URI}/admin/groupList/roomList/${index}/testerList`, {
        ...token,
        ...{
          params: {
            pageNo: param.queryKey[1]?.page,
          },
        },
      });

    case "companyList":
      return axios.get(`${URI}/admin/groupList/companyList`, {
        ...paramsWithToken,
      });

    case "userList":
      return axios.get(`${URI}/admin/groupList/${index}/userList`, {
        ...paramsWithToken,
      });
    case "normList":
      return axios.get(`${URI}/admin/groupList/${index}/normList`, {
        ...paramsWithToken,
      });

    // 고객사 리스트를 가져오는 API 요청
    case "totalCompany":
      return axios.get(`${URI}/admin/companyList`, {
        ...token,
        ...{
          params: {
            perPage: param.queryKey[1].perPage,
            pageNo: param.queryKey[1].pageNo,
            companyName: param.queryKey[1].search.companyName,
            orderType: param.queryKey[1].orderType,
            orderBy: param.queryKey[1].orderBy,
          },
        },
      });

    // 공고의 화상룸의 응시자들 전화 요청 상태를 가져오는 API 요청
    case "getCallStatus":
      index = param.queryKey[1]?.index;
      return axios.get(`${URI}/admin/groupList/roomList/${index}/call`, {
        ...token,
      });
    // 고객사의 담당자 리스트를 가져오는 API 요청
    case "getCompanyUserList":
      return axios.get(`${URI}/admin/companyList/${param.queryKey[1]}/userList`, {
        ...token,
        ...{ params: { companyIdx: param.queryKey[1] } },
      });

    // 삭제 예정 고객사 리스트를 가져오는 API 요청
    case "getRemovedCompany":
      return axios.get(`${URI}/admin/companyList/tempDelete`, {
        ...token,
        ...{
          params: {
            pageNo: param.queryKey[1]?.pageNo,
          },
        },
      });

    // 개인정보 삭제 예정 고객사의 담당자 리스트를 가져오는 API 요청
    case "getRemovedUser":
      return axios.get(`${URI}/admin/companyList/${param.queryKey[1]?.companyIdx}/userList/tempDelete`, {
        ...token,
      });

    // 고객사에서 사용하는 약관 목록을 불러오는 API 요청

    case "totalTerms":
      // index = param.queryKey[1]?.companyIdx;
      return axios.get(`${URI}/admin/companyList/termsList`, {
        ...token,
        ...{
          params: {
            pageNo: param.queryKey[1].pageNo,
            termsName: param.queryKey[1].search.termsName,
            perPage: param.queryKey[1].perPage,
          },
        },
      });

    // 고객사에서 사용할 수 있는 모든 규준 리스트를 가져오는 API 요청
    case "totalNorm":
      return axios.get(`${URI}/admin/companyList/normList`, {
        ...token,
        ...{
          params: {
            pageNo: param.queryKey[1].pageNo,
            normName: param.queryKey[1].search.normName,
          },
        },
      });

    // 고객사에서 사용하고 있는 규준 리스트를 가져오는 API 요청
    case "getNormByCompany":
      return axios.get(`${URI}/admin/companyList/${param.queryKey[1].companyIdx}/normList`, {
        ...token,
        ...{
          params: {
            pageNo: param.queryKey[1]?.pageNo,
          },
        },
      });

    // 고객사에서 사용하는 채팅 템플릿 목록을 불러오는 API [1:1 채팅 & 전체공지] 요청
    case "getChatTemplates":
      return axios.get(`${URI}/admin/companyList/chatTemplate`, {
        ...token,
        ...{ params: { companyIdx: param.queryKey[1].index } },
      });

    // 삭제 예정 채팅 템플릿 리스트를 가져오는 API 요청
    case "getRemovedChatTemplates":
      return axios.get(`${URI}/admin/companyList/${param.queryKey[1].index.companyIdx}/chatTemplate/tempDelete`, {
        ...token,
        ...{
          params: {
            pageNo: param.queryKey[1].pageNo,
          },
        },
      });

    // 선택한 레포트 타입의 샘플 데이터 양식을 다운로드하는 API
    case "reportDownload":
      const companyIdx = param.queryKey[1].companyIdx;
      const normIdx = param.queryKey[1].normIdx.normIdx;

      return axios.get(`${URI}/download/companyList/${companyIdx}/normList/${normIdx}/reportInfo`, { ...token });

    // 고객사에서 사용중인 규준에 적용 가능한 모든 레포트 템플릿 목록을 가져오는 API 요청
    case "totalReport":
      return axios.get(`${URI}/admin/companyList/report`, {
        ...token,
        ...{
          params: {
            pageNo: param.queryKey[1].pageNo,
            reportName: param.queryKey[1].search.reportName,
            perPage: param.queryKey[1].perPage,
            reportUseYN: param.queryKey[1].reportUseYN,
          },
        },
      });

    //
    // 선택한 고객사에서 사용중인 채팅 템플릿을 다운로드하는 API
    case "downloadTemplate":
      return axios.get(`${URI}/download/companyList/${param.queryKey[1].companyIdx}/chatTemplate`, {
        ...token,
        ...{
          params: {
            companyIdx: param.queryKey[1].companyIdx,
          },
        },
      });

    // ACG 플랫폼에 등록된 예제 리스트를 가져오는 API
    case "totalPractice":
      return axios.get(`${URI}/admin/practiceList`, {
        ...{
          ...token,
          params: {
            ...param.queryKey[1],
          },
        },
      });

    // 특정 예제의 상세 정보 리스트를 가져오는 API
    case "practiceDetail":
      return axios.get(`${URI}/admin/practiceList/${param.queryKey[1].practiceIdx}/detail`, {
        ...{
          ...token,
          params: {
            pageNo: param.queryKey[1].pageNo,
            practiceIdx: param.queryKey[1].pageNo,
          },
        },
      });
    // 예제의 문항과 보기 정보를 가져오는 API
    case "getPracticeQnA":
      const index = param.queryKey[1].practiceIdx;

      return axios.get(`${URI}/admin/practiceList/${index}/practice`, {
        ...{
          ...token,
          params: { ...param.queryKey[1].data },
        },
      });

    // 등록된 모든 문항 세트 리스트를 가져오는 API 요청

    case "getTotalQuestion":
      return axios.get(`${URI}/admin/questionList`, {
        ...{
          ...token,
          params: {
            ...param.queryKey[1],
          },
        },
      });
    // 특정 문항 세트의 상세 정보 리스트를 가져오는 API 요청
    case "questionDetail1":
      return axios.get(`${URI}/admin/questionList/${param.queryKey[1].questionIdx}/detail`, {
        ...{
          ...token,
          params: {
            ...param.queryKey[1],
          },
        },
      });

    // 문항 세트의 문항과 보기 정보를 가져오는 API 요청

    case "questionDetail2":
      return axios.get(`${URI}/admin/questionList/${param.queryKey[1].questionIdx}/question`, {
        ...{
          ...token,
          params: { ...param.queryKey[1].queryValue },
        },
      });

    // 공고에 등록할 회사의 본부/부문 리스트를 가져오는 API 요청
    case "partList":
      return axios.get(`${URI}/user/groupRegister/partList/${param.queryKey[1].companyIdx}`);

    default:
      break;
  }
};

export const postHandler = (flag, params) => {
  const token = getToken();
  let index;
  switch (flag) {
    case "login":
      return axios.post(`${URI}/login/admin`, params);
    case "login2fa":
      return axios.post(`${URI}/login/admin/2fa`, params);
    case "logout":
      return axios.post(`${URI}/login/admin/logout`, {}, token);

    case "updateGroup":
      return axios.patch(`${URI}/admin/groupList/${params.groupIdx}`, { ...params.submitValues }, token);

    //화상룸의 담당 매니저에게 알림톡을 개별 발송하는 API 요청
    case "noticeManager":
      return axios.post(
        `${URI}/admin/groupList/roomList/noticeManager`,
        { roomIdxs: params.roomIdx },
        { params: { noticeType: params.noticeType }, ...token }
      );

    // 공고의 화상룸 매니저들에게 알림톡/문자를 일괄적으로 발송하는 API 요청
    case "noticeManagerAll":
      return axios.post(`${URI}/admin/groupList/roomList/${params.groupIdx}/call`, null, {
        params: { noticeType: params.noticeType },
        ...token,
      });

    // 공고의 화상룸의 응시자들에게 전화 요청을 일괄적으로 보내는 API 요청
    case "callAll":
      return axios.post(`${URI}/admin/groupList/roomList/${params.roomIdx}/call`, null, {
        params: { roomIdx: params.roomIdx },
        ...token,
      });

    case "restoreQnA": {
      return axios.patch(`${URI}/admin/qnaList/${params.qnaIdx}/tempDelete`, {}, token);
    }
    case "deleteQnA":
      return axios.delete(`${URI}/admin/qnaList/${params.qnaIdx}`, {
        data: { eventReason: params.eventReason },
        ...token,
      });

    // 고객사를 등록하는 API 요청
    case "createCompany":
      return axios.post(
        `${URI}/admin/companyList`,
        {
          ...params.values,
        },
        { ...token }
      );

    // 고객사 정보를 수정하는 API 요청
    case "updateCompany":
      return axios.patch(`${URI}/admin/companyList/${params.index}`, { ...params.values }, { ...token });

    // 고객사를 삭제하는 API 요청 (논리 삭제)
    case "removeCompany":
      console.log("params: ", params);
      return axios.delete(`${URI}/admin/companyList/${params.companyIdx}`, {
        data: { eventReason: params.eventReason },
        ...token,
      });

    // 고객사의 담당자를 삭제하는 API 요청 (논리 삭제)

    case "removeUser":
      console.log("params: ", params);
      return axios.delete(`${URI}/admin/companyList/userList/${params.userIdx}`, {
        data: { eventReason: params.eventReason },
        ...token,
      });

    // 삭제 예정 고객사를 복원하는 API 요청
    case "restoreCompany":
      console.log("params: ", params);
      return axios.patch(`${URI}/admin/companyList/${params.companyIdx}/tempDelete`, {}, token);

    // 개인정보 삭제 예정 고객사의 담당자를 복원하는 API 요청
    case "restoreUser":
      console.log("params: ", params);
      return axios.patch(`${URI}/admin/companyList/userList/${params.userIdx}/tempDelete`, {}, token);

    // [HR담당자] 회원가입 API
    case "registHRUser":
      console.log(params);
      return axios.post(`${URI}/signup/user/${params.index}`, params.values, {
        ...token,
      });

    // 고객사의 로고 파일을 업로드하는 API
    case "uploadLOGO":
      console.log(params);
      return axios.post(
        `${URI}/upload/admin/company/${params.companyIdx}/logo`,
        { logo: params.logo },
        {
          ...{
            headers: {
              "Content-Type": "multipart/form-data",
              ...token.headers,
            },
          },
        }
      );
    // 고객사의 배경이미지 파일을 업로드하는 API
    case "uploadBgImage":
      return axios.post(
        `${URI}/upload/admin/company/${params.companyIdx}/bgImage`,
        { bgImage: params.bgImage },
        {
          ...{
            headers: {
              "Content-Type": "multipart/form-data",
              ...token.headers,
            },
          },
        }
      );

    // 고객사 레이아웃 디자인 정보를 수정하는 API 요청
    case "updateLayout":
      return axios.patch(`${URI}/admin/companyList/${params.companyIdx}/layout`, { ...params.submitValues }, token);

    // 고객사에서 사용하는 약관 수정 API 요청

    case "updateTerms":
      return axios.patch(`${URI}/admin/companyList/termsList/${params.termsIdx.termsIdx}`, { ...params.submitData }, token);

    // 고객사에서 사용하는 약관 삭제 (논리 삭제) API 요청
    case "deleteTerm":
      return axios.delete(`${URI}/admin/companyList/termsList/${params.termsIdx}`, {
        data: { eventReason: params.eventReason },
        ...token,
      });

    // 고객사에서 사용 할 규준(복수 선택 가능) 등록 API 요청
    case "addNorms":
      return axios.post(
        `${URI}/admin/companyList/${params.companyIdx}/normList`,
        {
          normIdxList: params.normIdxList,
        },
        token
      );

    // 고객사에서 사용 중인 규준 삭제 API 요청

    case "deleteNorm":
      console.log(params);
      return axios.delete(`${URI}/admin/companyList/${params.companyIdx}/normList/${params.normIdx}`, {
        data: { eventReason: params.eventReason },
        ...token,
      });

    // 고객사에서 사용 중인 규준 활성화 & 비활성화 API 요청
    case "normUse":
      return axios.patch(`${URI}/admin/companyList/${params.companyIdx}/normList/${params.normIdx}`, {}, { ...token, params });

    // 고객사별 채팅 템플릿 추가 [1:1 채팅 & 전체공지] 요청
    case "addTemplate":
      return axios.post(
        `${URI}/admin/companyList/chatTemplate`,
        { ...params.submitValue },
        { ...token, params: { companyIdx: params.companyIdx } }
      );

    // 고객사별 채팅 템플릿 삭제 (논리 삭제) [1:1 채팅 & 전체공지]
    case "deleteTemplate":
      return axios.delete(`${URI}/admin/companyList/chatTemplate/${params.chatTemplateIdx}`, {
        data: { eventReason: params.eventReason },
        ...token,
      });

    // 삭제 예정 채팅 템플릿을 복원하는 API 요청
    case "restoreChatTemplate":
      return axios.patch(`${URI}/admin/companyList/chatTemplate/${params.chatTemplateIdx}/tempDelete`, {}, { ...token });

    // 고객사별 채팅 템플릿 수정 [1:1 채팅 & 전체공지] 요청
    case "updateChatTemplate":
      console.log("params: ", params);
      return axios.patch(`${URI}/admin/companyList/chatTemplate/${params.chatTemplateIdx}`, { ...params.submitValue }, { ...token });

    // [HR담당자] HR담당자 백도어 로그인 API
    case "backdoorLogin":
      console.log("params: ", params);
      return axios.post(`${URI}/login/user/${params.userIdx}/backdoor`, {}, { ...token });

    // 레포트 템플릿 파일을 업로드하는 API
    case "uploadReportTemplate":
      const type = params.type;
      const body = params.body;
      return axios.post(
        `${URI}/upload/admin/report/template/${type}`,
        { reportTemplate: body },
        {
          ...{
            headers: {
              "Content-Type": "multipart/form-data",
              ...token.headers,
            },
          },
        }
      );

    // 레포트 템플릿을 생성하는 API
    case "createReport":
      return axios.post(`${URI}/admin/companyList/report`, { ...params.body }, { ...token });

    // 레포트 탬플릿 삭제
    case "deleteReportTemplate":
      return axios.delete(`${URI}/modify/template`, {
        data: {
          eventReason: params.eventReason,
          reportIdxList: params.reportIdxList,
        },
        ...token,
      });

    // 레포트 템플릿의 사용 여부 상태를 변경하는 API 요청

    case "reportUseYN":
      const reportIdx = params.reportIdx;
      return axios.patch(`${URI}/admin/companyList/report/${reportIdx}`, {}, { ...token, params: { reportUseYN: params.reportUseYN } });

    // 고객사에서 사용중인 규준의 레포트 템플릿을 변경하는 API 요청
    case "applyReport":
      return axios.patch(
        `${URI}/admin/companyList/${params.companyIdx}/normList/${params.normIdx}/report`,
        {},
        { ...token, params: { reportIdx: params.reportIdx } }
      );

    // 관리자 아이디 중복 체크 API
    case "idCheck":
      return axios.post(`https://test-acg-portal.insahr.co.kr/auth/idCheck`, {
        ...params,
      });

    // 관리자 통합 회원가입 API
    case "signUpSUPER":
      return axios.post(`https://test-acg-portal.insahr.co.kr/auth/signup`, {
        ...params.data,
      });

    // 관리자 이메일 인증 API
    case "authEmail":
      const index = params.adminIdx;
      return axios.patch(
        `https://test-acg-portal.insahr.co.kr/auth/signup/${params.adminIdx}/emailAuth`,
        {},
        { params: { adminIdx: index } }
      );

    // 특정 약관을 복제하는 API 요청
    case "copyTerm":
      return axios.post(`${URI}/admin/companyList/termsList/${params.termsIdx}`, {}, { ...token, params: { termsIdx: params.termsIdx } });

    // 레포트 템플릿 정보를 변경하는 API 요청
    case "updateTemplate":
      return axios.put(
        `${URI}/admin/companyList/report/${params.reportIdx}`,
        { ...params.submitValues },
        { ...token, params: { termsIdx: params.termsIdx } }
      );

    // 고객사의 담당자 회원가입 안내 메일 API 요청

    case "sendSignUpEmail":
      return axios.post(
        `${URI}/admin/companyList/userList/${params.companyIdx}`,
        {
          userGradeName: params.userGradeName,
          emailText: params.emailText,
          userEmail: params.userEmail,
        },
        { ...token, params: { companyIdx: params.companyIdx } }
      );

    // [관리자] 현재 로그인된 관리자 로그아웃하는 API
    case "logoutAdmin":
      return axios.post(`${URI}/login/admin/logout`, null, { ...token });

    // 예제를 신규 등록하는 API 요청
    case "createPracticeGroup":
      return axios.post(`${URI}/admin/practiceList`, { ...params.values }, { ...token });

    // 예제 정보를 수정하는 API 요청
    case "updatePracticeGroup":
      return axios.patch(`${URI}/admin/practiceList/${params.practiceIdx}`, params.values, {
        ...token,
        params: { practiceIdx: params.practiceIdx },
      });

    // 예제 정보를 삭제하는 API 요청
    case "deletePracticeGroup":
      return axios.delete(`${URI}/admin/practiceList/${params.practiceIdx}`, {
        ...token,
      });
    // 예제 팝업 이미지 파일을 업로드하는 API
    case "upload_AnswerImg":
      const formData = new FormData();
      formData.append("popupimg", params.answerImg);
      return axios.post(`${URI}/upload/admin/practice/${params.practiceIdx}/${params.questionNo}/popupimg`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          ...token.headers,
        },
      });

    // 예제의 문항과 보기 정보를 등록하는 API 요청
    case "createPracticeDetail":
      return axios.post(`${URI}/admin/practiceList/${params.practiceIdx}/practice`, params.values, {
        ...token,
        params: { practiceIdx: params.practiceIdx },
      });

    // 예제의 문항과 보기 정보를 수정하는 API 요청
    case "updatePracticeDetail":
      return axios.patch(`${URI}/admin/practiceList/${params.practiceIdx}/practice`, params.values, {
        ...token,
        params: { practiceIdx: params.practiceIdx },
      });
    // 예제의 문항과 보기 정보를 삭제하는 API 요청
    case "deleteOnePractice":
      return axios.delete(`${URI}/admin/practiceList/${params.practiceIdx}/practice/${params.questionNo}`, {
        data: { ...params },
        ...token,
      });
    //* ============= 문항관리 =============
    // 문항을 신규 등록하는 API 요청
    case "createQuestionGroup":
      return axios.post(`${URI}/admin/questionList`, { ...params.values }, { ...token });
    // 문항 세트 정보를 삭제하는 API 요청
    case "deleteQuestionGroup":
      return axios.delete(`${URI}/admin/questionList/${params.questionIdx}`, {
        ...token,
      });

    // 문항 세트 정보를 수정하는 API 요청
    case "updateQuestionGroup":
      return axios.patch(`${URI}/admin/questionList/${params.questionIdx}`, params.values, {
        ...token,
        params: { questionIdx: params.questionIdx },
      });

    // 문항 세트의 문항과 보기 정보를 삭제하는 API 요청
    case "deleteOneQuestion":
      return axios.delete(`${URI}/admin/questionList/${params.questionIdx}/question/${params.questionNo}`, {
        data: { ...params },
        ...token,
      });

    // 문항 세트의 문항과 보기 정보를 등록하는 API 요청
    case "createQuestionDetail":
      return axios.post(`${URI}/admin/questionList/${params.questionIdx}/question`, params.values, {
        ...token,
        params: { questionIdx: params.questionIdx },
      });

    // 문항 지문 이미지 파일을 업로드하는 API
    case "upload_question_QuestionImg":
      const formDataQuestion = new FormData();
      formDataQuestion.append("subimg", params.questionImg);
      return axios.post(`${URI}/upload/admin/question/${params.questionIdx}/${params.questionNo}/subimg`, formDataQuestion, {
        headers: {
          "Content-Type": "multipart/form-data",
          ...token.headers,
        },
      });

    // 문항의 보기 이미지 파일을 업로드하는 API
    case "upload_question_ExImg":
      const formDataExImg = new FormData();
      formDataExImg.append("eximg", params.questionImg);
      return axios.post(
        `${URI}/upload/admin/question/${params.questionIdx}/${params.questionNo}/${params.questionExNo}/eximg`,
        formDataExImg,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...token.headers,
          },
        }
      );
    // 문항 세트의 문항과 보기 정보를 수정하는 API 요청
    case "updateQuestionDetail":
      return axios.patch(`${URI}/admin/questionList/${params.questionIdx}/question`, params.values, {
        ...token,
        params: { questionIdx: params.questionIdx },
      });

    // [HR담당자] HR담당자 권한 수정 API
    case "updateGrade":
      return axios.patch(`${URI}/user/role/stat/${params.userIdx}`, {}, { ...token, params: { userIdx: params.userIdx } });

    // [HR담당자] HR담당자 삭제 API
    case "deleteManager":
      return axios.delete(`${URI}/user/delete/stat/${params.userIdx}`, {
        data: { eventReason: params.eventReason },
        ...token,
      });

    default:
      break;
  }
};
