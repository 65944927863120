import { useMemo } from "react";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Link, useNavigate, useParams } from "react-router-dom";

import useMutationHook from "hooks/useMutationHook";

import LOGO from "assets/image/logo/CJ.png";
import { modalWrapper } from "components/templates/modalConfig";
import MenuComponent from "components/UI/menu/MenuComponent";
import { DrawerHeader } from "components/UI/nav/DrawerHeader";
import NavMenuList from "components/UI/nav/NavMenuList";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { HRcompanyInfo, isNavOpen } from "recoil/store";

import {
  ClipboardList,
  MessagesSquare,
  Search,
  UserSquare,
} from "lucide-react";
export default function NavHR() {
  const navigate = useNavigate();
  const isOpen = useRecoilValue(isNavOpen);
  const companyInfo = useRecoilValue(HRcompanyInfo);
  const { company } = useParams();

  const refreshContent = () => {
    navigate(`/acghr_hr/${company}/group`);
    navigate(0);
  };

  const { mutate } = useMutationHook("logoutHR");

  const afterConfirm = () => {
    sessionStorage.clear();
    localStorage.removeItem(company);
    navigate("/login_user");
  };

  const handleLogout = () => {
    modalWrapper({
      text: "로그아웃 하시겠습니까?",
      title: "로그아웃",
      icon: "question",
      func: mutate,
      successText: "로그아웃 되었습니다.",
      afterConfirmAction: afterConfirm,
    });
  };

  useEffect(() => {
    document.title = `CJ CFT - HR`;
  }, []);

  const handleMoveEditUser = () => {
    navigate(`/acghr_hr/${company}/updateUser`);
  };

  const navMenu_client = useMemo(() => {
    return [
      {
        depth1Title: "검사/운영",
        sub: [
          {
            depth2Title: "채용그룹 관리",
            icon: <ClipboardList size={16} strokeWidth={1.8} />,
            path: `/acghr_hr/${company}/group`,
          },
          {
            depth2Title: "HR담당자 관리",
            icon: <UserSquare size={16} strokeWidth={1.8} />,
            path: `/acghr_hr/${company}/manager`,
          },
        ],
      },
    ];
  }, [company]);

  const navMenu = useMemo(() => {
    return [
      {
        depth1Title: "검사/운영",
        sub: [
          {
            depth2Title: "채용그룹 관리",
            icon: <ClipboardList size={16} strokeWidth={1.8} />,
            path: `/acghr_hr/${company}/group`,
          },
          {
            depth2Title: "HR담당자 관리",
            icon: <UserSquare size={16} strokeWidth={1.8} />,
            path: `/acghr_hr/${company}/manager`,
          },
          {
            depth2Title: "응시자 Q&A",
            icon: <MessagesSquare size={16} strokeWidth={1.8} />,
            path: `/acghr_hr/${company}/qna`,
          },
          {
            depth2Title: (
              <Typography>
                응시자 검색 및 <br /> 기응시 확인
              </Typography>
            ),
            icon: <Search size={16} strokeWidth={1.8} />,
            path: `/acghr_hr/${company}/searchTester`,
          },
        ],
      },
    ];
  }, [company]);

  return (
    <>
      <Drawer
        variant="persistent"
        anchor={"left"}
        open={isOpen}
        sx={{
          width: 260,
          flexShrink: 0,

          [`& .MuiDrawer-paper`]: {
            width: 260,
            boxSizing: "border-box",
            border: "none",
            padding: "0px 20px 0px 30px",
            backgroundColor: "#f8f9fa",
          },
        }}
      >
        <Link onClick={refreshContent}>
          <DrawerHeader logo={LOGO} />
        </Link>

        <Box mt={3} backgroundColor={"white"} borderRadius={2}>
          <Grid container alignItems={"center"} my={2} justifyContent="center">
            <MenuComponent
              icon={<MenuRoundedIcon />}
              list={[
                { title: "개인정보수정", function: handleMoveEditUser },
                {
                  title: "로그아웃",
                  function: handleLogout,
                  sx: { color: "red" },
                },
              ]}
            />
            <Typography sx={{ wordBreak: "keep-all" }} fontWeight={700}>
              {companyInfo.userName} 님
            </Typography>
            <Typography>, 안녕하세요 🙇‍♂️</Typography>
          </Grid>
          <Divider />

          <NavMenuList
            menuListTitle={
              companyInfo.companyIdx === 1 ? navMenu : navMenu_client
            }
          />
        </Box>
      </Drawer>
    </>
  );
}
