import { Stack, Typography } from "@mui/material";
import React from "react";
// import { ReactComponent as Empty } from "assets/icon/empty_placeholder.svg";
import { ReactComponent as Empty } from "assets/icon/LineMdAlertCircle.svg";
const EmptyPlaceholder = ({ message = "데이터가 없습니다" }) => {
  return (
    <Stack alignItems="center">
      <Empty />
      <Typography color="#777" variant="h6">
        {message}
      </Typography>
    </Stack>
  );
};

export default EmptyPlaceholder;
