import { Button, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { companyIdxAtom, modalAtom } from "recoil/store";
import { theme } from "themes/theme";
import { postHandler } from "utils/requestURI";

export default function UploadBtn({
  isLogoUse,
  isBgColor,
  name,
  setImgSrc,
  info,
  setSubmitValues,
}) {
  const companyIdx = useRecoilValue(companyIdxAtom);
  const [modalOn, setModalOn] = useRecoilState(modalAtom);

  const { mutate, data, isSuccess, isError } = useMutation(
    (submitValue) => {
      return postHandler(
        name === "logo" ? "uploadLOGO" : "uploadBgImage",
        submitValue
      );
    },
    {
      onSuccess: (res) => {
        console.log("res: ", res);
        if (res.status === 200 || res.status === 201) {
          setModalOn({
            modalState: !modalOn.modalState,
            title: "파일 업로드",
            type: "alert",
            subTitle: res.data.message,
            status: "success",
            modalFunc: () => {
              setModalOn({ modalState: false });
            },
          });
        }
      },
      onError: (error) => {
        if (error.response.status >= 400) {
          setModalOn({
            modalState: !modalOn.modalState,
            title: "파일 업로드",
            type: "alert",
            subTitle: error.response.data.message,
            status: "error",
            modalFunc: () => {
              setModalOn({ modalState: false });
            },
          });
        }
      },
    },
    { useErrorBoundary: (error) => console.log(error) }
  );

  const handleUpload = async (e, name) => {
    name === "logo"
      ? await mutate(
          {
            companyIdx: companyIdx,
            logo: e.target.files[0],
          },
          {
            onSuccess: (res) => {
              setSubmitValues((prev) => ({
                ...prev,
                ciFilePath: res.data.data,
              }));
            },
            onError: (error) => {
              setModalOn({
                modalState: !modalOn.modalState,
                title: "파일 업로드",
                type: "alert",
                subTitle: error.response.data.message,
                status: "error",
                modalFunc: () => {
                  setModalOn({ modalState: false });
                },
              });
            },
          }
        )
      : await mutate(
          { companyIdx: companyIdx, bgImage: e.target.files[0] },
          {
            onSuccess: (res) => {
              setSubmitValues((prev) => ({
                ...prev,
                defaultBgURL: res.data.data,
              }));
            },
            onError: (error) => {
              setModalOn({
                modalState: !modalOn.modalState,
                title: "파일 업로드",
                type: "alert",
                subTitle: error.response.data.message,
                status: "error",
                modalFunc: () => {
                  setModalOn({ modalState: false });
                },
              });
            },
          }
        );
  };

  // useEffect(() => {
  //   isSuccess && setImgSrc((prev) => ({ ...prev, [name]: data.data.data }));
  // }, [isSuccess || isError]);

  const urlInfo = (info, name) => {
    if (name === "logo") return info.ciFilePath;
    else if (name === "bgImage") return info.bgColor;
  };

  const disableFlag = (info, name) => {
    if (name === "logo") return info.ciUseYN === "N" ? true : false;
    else if (name === "bgImage")
      return info.bgImageUseYN === "N" ? true : false;
  };

  return (
    <Grid width={"100%"}>
      <Button
        fullWidth
        disabled={disableFlag(info, name)}
        variant="outlined"
        component="label"
        sx={{ justifyContent: "space-between" }}
        children={
          <>
            <Typography maxWidth={90} noWrap variant="body2">
              {urlInfo(info, name)}
            </Typography>
            <Typography
              sx={{
                border:
                  isLogoUse === "Y" || isBgColor === false
                    ? "1px solid black"
                    : "1px solid #d2d2d2",
                borderRadius: "5px",
                padding: "0 7px",
                background:
                  isLogoUse === "Y" || isBgColor === false
                    ? theme.palette.primary.main
                    : grey[200],

                color:
                  isLogoUse === "Y" || isBgColor === false ? "white" : "grey",
              }}
            >
              업로드
            </Typography>
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => handleUpload(e, name)}
            />
          </>
        }
      />
      <Typography variant="body2" color={"#777"} textAlign="right">
        {name === "bgImage"
          ? "용량은 5MB로 제한됩니다."
          : "용량은 300KB로 제한됩니다."}
      </Typography>
    </Grid>
  );
}
