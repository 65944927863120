import { Button, Grid, TextField, Typography } from "@mui/material";
// import { navigate } from "@storybook/addon-links";
import { alertConfirm, alertInfo } from "common/alert";
import { REQUIRE_VALUE } from "enums/errorMessage";
import { MESSAGE_UPDATE_USER } from "enums/modalMessage";
import useMutationHook from "hooks/useMutationHook";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { HRcompanyInfo, modalAtom } from "recoil/store";
import { autoHyphen, autoHyphen_UserTel } from "utils/autoHyphen";

//* 기본정보 변경 컴포넌트
const UpdateBasicInfo = () => {
  const companyInfo = useRecoilValue(HRcompanyInfo);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userTel: companyInfo?.userTel || "",
      userName: companyInfo?.userName || "",
    },
  });
  const navigate = useNavigate();
  const { mutate } = useMutationHook("updateUserBasicInfo", "HR");
  const [modalOn, setModalOn] = useRecoilState(modalAtom);

  const onSubmit = (data) => {
    setModalOn({
      modalState: !modalOn.modalState,
      subTitle: MESSAGE_UPDATE_USER,
      type: "confirm",
      title: "회원정보 수정",
      status: "warning",
      modalFunc: () => {
        mutate(
          { data },
          {
            onSuccess: () => {
              setModalOn({
                modalState: !modalOn.modalState,
                title: "회원정보 수정",
                type: "alert",
                subTitle: "회원정보수정이 완료되었습니다.",
                status: "success",
                modalFunc: () => {
                  navigate("/login_user");
                },
              });
            },
          }
        );
      },
    });
  };

  return (
    <>
      <form>
        <Grid container flexDirection={"column"} rowGap={1}>
          <Grid container flexDirection={"column"}>
            <Typography fontWeight={700}>기본정보</Typography>
            <Grid container flexDirection={"column"} rowGap={2} p={2}>
              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography variant="body2">이메일</Typography>
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  value={companyInfo?.userEmail}
                  placeholder="_________@____________.____"
                />
              </Grid>

              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography
                  variant="body2"
                  sx={{
                    "&:after": {
                      content: '"*"',
                      color: "red",
                    },
                  }}
                >
                  담당자 이름
                </Typography>

                <Controller
                  name="userName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRE_VALUE,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      helperText={errors && errors.userName?.message}
                      error={errors.userName && true}
                      FormHelperTextProps={{
                        sx: {
                          margin: 0,
                        },
                      }}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography variant="body2">직통번호</Typography>
                <Controller
                  name="userTel"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={(e) => {
                        onChange(autoHyphen_UserTel(e.target.value));
                      }}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container columnGap={2} justifyContent="flex-end">
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              변경
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UpdateBasicInfo;
