import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import { useParams } from "react-router-dom";
import { practicePageDetailAtom } from "recoil/store";
import { useRecoilValue } from "recoil";

import useMutationHook from "hooks/useMutationHook";
import { focusManager, useQueryClient } from "@tanstack/react-query";

import { ReactComponent as Upload } from "assets/icon/uploadIcon.svg";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Tooltip } from "@mui/material";
import { MESSAGE_CREATE_PRACTICE, MESSAGE_UPLOAD } from "enums/modalMessage";
import { toImgURL } from "utils/extractImgURL";
import Swal from "sweetalert2";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";
import "question.css";

import katex from "katex";
import "katex/dist/katex.min.css";
import { Check, Plus, Settings, Trash2, X } from "lucide-react";

const fontArray = [
  "9px",
  "10px",
  "11px",
  "12px",
  "13px",
  "14px",
  "15px",
  "16px",
  "18px",
  "19px",
  "20px",
  "21px",
  "22px",
  "23px",
  "24px",
];

var Size = Quill.import("attributors/style/size");
Size.whitelist = fontArray;
Quill.register(Size, true);
window.katex = katex;

const CreateOneAptitudePractice = ({ onClose, pageNo }) => {
  const imageHandler = (type) => {
    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener("change", async () => {
      const file = input.files[0];

      if (type === "subText") {
        upload_QuestionImg(
          {
            practiceIdx: Number(id),
            questionNo: questionNoRef.current.value,
            questionImg: file,
          },
          {
            onSuccess: (res) => {
              // setQuestionImg(res.data.data);
              const editor = questionSubTextRef.current.getEditor(); // 에디터 객체 가져오기
              const range = editor.getSelection();
              editor.insertEmbed(range.index, "image", toImgURL(res.data.data));
            },
          }
        );
      }
      //  else {
      //   upload_QuestionExImg(
      //     {
      //       practiceIdx: Number(id),
      //       questionNo: questionNoRef.current.value,
      //       questionImg: file,
      //       questionExNo: practiceExRegistList.length + 1,
      //     },
      //     {
      //       onSuccess: (res) => {
      //         // setQuestionImg(res.data.data);
      //         const editor = exListRef.current.getEditor(); // 에디터 객체 가져오기
      //         const range = editor.getSelection();
      //         editor.insertEmbed(range.index, "image", toImgURL(res.data.data));
      //       },
      //     }
      //   );
      // }
    });
  };

  const subText_modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ align: [] }],
          ["image"],
          [{ header: [1, 2, 3, false] }],
          [
            {
              size: fontArray,
            },
          ], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ script: "sub" }, { script: "super" }],
        ],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: () => imageHandler("subText"),
        },
        // ImageResize: {
        //   parchment: Quill.import("parchment"),
        // },
      },
    };
  }, []);
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ align: [] }],
          // ["formula"],
          [{ header: [1, 2, 3, false] }],
          [
            {
              size: fontArray,
            },
          ], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ script: "sub" }, { script: "super" }],
        ],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          // image: () => imageHandler("practice"),
        },
        // ImageResize: {
        //   parchment: Quill.import("parchment"),
        // },
      },
    };
  }, []);

  const exList_modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ align: [] }],
          ["formula"],
          [{ header: [1, 2, 3, false] }],
          [
            {
              size: fontArray,
            },
          ], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ script: "sub" }, { script: "super" }],
        ],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: () => imageHandler("exList"),
        },
        // ImageResize: {
        //   parchment: Quill.import("parchment"),
        // },
      },
    };
  }, []);

  // 위에서 설정한 모듈들 foramts을 설정한다
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "image",
    "align",
    "script",
    "size",
    "formula",
  ];
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { likertYN, ipsativeYN } = useRecoilValue(practicePageDetailAtom);

  const [questionSubUseYN, setQuestionSubUseYN] = useState("Y");
  const [practiceExRegistList, setPracticeExRegistList] = useState([]);

  const exListRef = useRef();
  const questionSubTextRef = useRef();
  const questionTextRef = useRef();
  const questionNoRef = useRef();
  const buttonNamePopUpRef = useRef();

  const [isEditMode, setIsEditMode] = useState(false);

  const [questionExLayout, setQuestionExLayout] = useState(1);
  const handleQuestionExLayout = (e) => {
    setQuestionExLayout(e.target.value);
  };

  //* 예제 등록 Mutation
  const { mutate } = useMutationHook("createPracticeDetail");

  //* 해설 파일 업로드
  const { mutate: upload_AnswerImg } = useMutationHook("upload_AnswerImg");

  //* 문제 이미지  업로드
  const { mutate: upload_QuestionImg } = useMutationHook("upload_QuestionImg");

  //* 보기 이미지 업로드
  const { mutate: upload_QuestionExImg } = useMutationHook("upload_practice_ExImg");

  const handleQuestionSubUse = (e) => {
    setQuestionSubUseYN(e.target.value);
  };
  const [보기, set보기] = useState("");

  const handleAddrow = (_) => {
    set보기("");
    setPracticeExRegistList((prev) => [
      ...prev,
      {
        questionExText: 보기,
        questionExNo: prev[prev?.length - 1]?.questionExNo + 1 || 1,
      },
    ]);
  };

  const handleRemoveRow = (_, index) => {
    setPracticeExRegistList(
      practiceExRegistList.filter((item) => item.questionExNo !== practiceExRegistList[index].questionExNo)
    );
  };

  const [targetItemIdx, setTargetItemIdx] = useState(0);
  const handleUpdate보기 = (_, item) => {
    setIsEditMode(!isEditMode);
    set보기(item.questionExText);
    setTargetItemIdx(item.questionExNo);
  };

  const questionExList = (practiceExRegistList) => {
    return practiceExRegistList.map((n, index) => {
      return (
        <Grid container columnGap={1} alignItems="center" justifyContent={"space-between"} key={n.questionExNo}>
          <Box>
            <Grid item container columnGap={1} alignItems="center">
              <Grid
                borderRadius={"50%"}
                border="1px solid black"
                width={"24px"}
                height={"24px"}
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  "&": {
                    background: n.answerScore >= 1 ? "black" : "white",
                    color: n.answerScore >= 1 ? "white" : "black",
                  },
                  "&:hover": {
                    boxShadow: "0px 0 0 0.2rem rgb(92 92 92/50%);",
                  },
                }}
              >
                <Typography fontSize="14px" fontWeight={500}>
                  {index + 1}
                </Typography>
              </Grid>

              <Typography
                m={0}
                p={0}
                fontSize="18px"
                sx={{ whiteSpace: "pre-wrap", "&>p": { m: 0 } }}
                dangerouslySetInnerHTML={{
                  __html: n?.questionExText,
                }}
              />
            </Grid>
          </Box>
          <Box>
            <Grid item container columnGap={1}>
              <Grid>
                <Tooltip title="수정하기" arrow placement="top">
                  <Box component="span">
                    <IconButton
                      sx={{
                        color: "#424242",
                        border: "0.5px solid #b7b7b7",
                      }}
                      size="small"
                      onClick={(e) => handleUpdate보기(e, n)}
                    >
                      <Settings size={15} />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="삭제하기" arrow placement="top">
                  <Box component="span">
                    <IconButton
                      sx={{
                        border: "0.5px solid #c42c08",
                        color: "#c42c08",
                      }}
                      color="removeButton"
                      size="small"
                      variant="contained"
                      onClick={(e) => handleRemoveRow(e, index)}
                    >
                      <Trash2 size={15} />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      );
    });
  };

  const handleChange보기 = () => {
    setPracticeExRegistList((prev) => {
      const newObj = prev;
      newObj[targetItemIdx - 1] = {
        questionExText: 보기,
        questionExNo: targetItemIdx,
      };
      return newObj;
    });
    setIsEditMode(false);
    set보기("");
  };

  //* Mutation 후 이벤트
  const alertResult = useCallback((key, key2) => {
    onClose();
    queryClient.invalidateQueries({
      queryKey: [key],
    });
    queryClient.invalidateQueries({
      queryKey: [key2],
    });
  }, []);

  const [imgPreview, setImgPreview] = useState("");

  const handleUpload = (e) => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPLOAD,
        title: "해설 이미지 업로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        upload_AnswerImg(
          {
            practiceIdx: Number(id),
            questionNo: Number(questionNoRef.current.value),
            answerImg: e.target.files[0],
          },
          {
            onSuccess: (res) => {
              Swal.fire(
                alertModalConfig({
                  text: "이미지 업로드가 완료되었습니다.",
                  title: "해설 이미지 업로드",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) setImgPreview(res.data.data);
              });
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  text: "이미지 업로드 실패",
                  title: "해설 이미지 업로드",
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
      }
    });
  };

  useEffect(() => {
    focusManager.setFocused(false);
    return () => focusManager.setFocused(undefined);
  }, []);

  const handle보기 = (e) => {
    set보기(e);
  };

  const handleSubmit = () => {
    const submitValues = {
      pageNo: pageNo,
      likertYN: likertYN,
      ipsativeYN: ipsativeYN,
      practiceTitleRegistInfo: {
        questionNo: Number(questionNoRef.current.value),
        questionSubText: questionSubUseYN === "Y" ? questionSubTextRef.current.value : null,
        questionText: questionTextRef.current.value,
        buttonNamePopUp: buttonNamePopUpRef.current.value,
        questionSubUseYN: questionSubUseYN,
        questionExLayout: Number(questionExLayout),
        popUpImg: imgPreview,
      },
      practiceExRegistList: practiceExRegistList.map((item, index) => {
        return {
          questionExText: item.questionExText,
          questionExNo: index + 1,
        };
      }),
    };

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_CREATE_PRACTICE,
        title: "문항등록",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          { practiceIdx: Number(id), values: submitValues },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 등록이 완료되었습니다.",
                  title: "문항등록",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("getPracticeQnA", "practiceDetail");
              });
            },
          }
        );
      }
    });
  };

  return (
    <>
      <DialogContent>
        <Grid rowGap={4} container mt={2} flexDirection="row">
          <Grid item xs={12}>
            <Grid item xs={1.2}>
              <Title>문항번호</Title>
              <TextField
                size="small"
                type={"number"}
                name={"companyName"}
                inputRef={questionNoRef}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Title>질문</Title>

            <ReactQuill
              ref={questionTextRef}
              modules={modules}
              formats={formats}
              className={"questionText"}
              style={{ border: "1px solid #dbdbdb" }}
            />
          </Grid>

          <Grid item xs={12}>
            <Title>지문사용</Title>

            <FormControl>
              <RadioGroup value={questionSubUseYN} name="measure" onChange={handleQuestionSubUse} row>
                <FormControlLabel value="Y" control={<Radio />} label="예" />
                <FormControlLabel value="N" control={<Radio />} label="아니오" />
              </RadioGroup>
            </FormControl>
            {questionSubUseYN === "Y" && (
              <Grid bgcolor={"#e9e9e99d"} borderRadius={2} p={1.5}>
                <Title>지문</Title>
                <ReactQuill
                  ref={questionSubTextRef}
                  modules={subText_modules}
                  formats={formats}
                  className="questionSubUseYN"
                  style={{
                    border: "1px solid #dbdbdb",
                    whiteSpace: "pre-wrap",
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Title>보기 레이아웃</Title>
            <Grid container justifyContent="center">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  name={"roomUseYN"}
                  size="small"
                  sx={{ justifyContent: "space-evenly" }}
                  value={questionExLayout}
                  onChange={handleQuestionExLayout}
                  // value={submitValues.roomUseYN}
                >
                  <FormControlLabel value={1} control={<Radio />} label="1행" />
                  <FormControlLabel value={2} control={<Radio />} label="2행" />
                  <FormControlLabel value={3} control={<Radio />} label="3행" />
                  <FormControlLabel value={4} control={<Radio />} label="4행" />
                  <FormControlLabel value={5} control={<Radio />} label="5행" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems={"center"} mb={1}>
              <Title>보기입력</Title>
              {!isEditMode && (
                <Tooltip title="보기 추가" arrow placement="top">
                  <Box component="span">
                    <Button
                      size="small"
                      variant="contained"
                      disableElevation
                      onClick={(e) => handleAddrow(e)}
                      startIcon={<Plus size={12} />}
                    >
                      보기 추가
                    </Button>
                  </Box>
                </Tooltip>
              )}
              {isEditMode && (
                <Grid item xs={4} container columnGap={1} justifyContent="flex-end">
                  <Tooltip title="수정하기" arrow placement="top">
                    <Box component="span">
                      <Button
                        startIcon={<Check size={13} />}
                        variant="contained"
                        disableElevation
                        onClick={handleChange보기}
                        size="small"
                      >
                        수정
                      </Button>
                    </Box>
                  </Tooltip>
                  <Tooltip title="취소" arrow placement="top">
                    <Box component="span">
                      <Button
                        startIcon={<X size={13} />}
                        variant="contained"
                        disableElevation
                        color="removeButton"
                        onClick={() => setIsEditMode(!isEditMode)}
                        size="small"
                        sx={{ "&": { color: "#c42c08" } }}
                      >
                        닫기
                      </Button>
                    </Box>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            <ReactQuill
              ref={exListRef}
              value={보기}
              onChange={handle보기}
              modules={exList_modules}
              style={{ border: "1px solid #dbdbdb", position: "relative" }}
              className="questionExText"
              formats={formats}
              theme="snow"
            />
            <Grid container rowGap={1} mt={1}>
              {questionExList(practiceExRegistList)}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Title>정답 및 해설 버튼 이름</Title>
            <TextField fullWidth size="small" name={"companyName"} inputRef={buttonNamePopUpRef} />
          </Grid>
          <Grid item xs={12}>
            <Title>정답 및 해설 이미지</Title>

            <Grid py={2} container justifyContent="center" flexDirection={"column"} rowGap={1} alignItems="center">
              {imgPreview === "" ? (
                <>
                  <Upload />
                  <Typography color={"#777"} textAlign="center" sx={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
                    {`파일을 직접 업로드하거나 \n드래그하여 업로드 해주세요 (최대 10MB)`}
                  </Typography>
                  <Button variant="outlined" size="small" component="label">
                    업로드
                    <input hidden accept="image/*" type="file" onChange={handleUpload} />
                  </Button>
                </>
              ) : (
                <Box
                  position="relative"
                  component="label"
                  sx={{
                    cursor: "pointer",
                    "&:hover > .image": {
                      opacity: 0.3,
                    },
                    "&:hover > .middle": {
                      opacity: 1,
                    },
                  }}
                >
                  <input hidden accept="image/*" type="file" onChange={handleUpload} />
                  <Box
                    className="image"
                    component="img"
                    src={imgPreview}
                    display="block"
                    width="100%"
                    height="auto"
                    sx={{
                      transition: ".5s ease",
                      opacity: 1,
                      backfaceVisibility: "hidden",
                    }}
                  />

                  <Box
                    sx={{
                      transition: ".5s ease",
                      opacity: 0,
                      transform: "translate(-50%, -50%)",
                    }}
                    className="middle"
                    position="absolute"
                    top="50%"
                    left="50%"
                    textAlign="center"
                  >
                    <Box class="text">
                      <IconButton
                        size="large"
                        component="label"
                        variant="contained"
                        sx={{
                          background: "#7c828d",
                          color: "white",
                        }}
                      >
                        <input hidden accept="image/*" type="file" onChange={handleUpload} />
                        <DriveFolderUploadRoundedIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button variant={"contained"} onClick={handleSubmit}>
          확인
        </Button>
        <Button variant={"outlined"} onClick={onClose}>
          취소
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateOneAptitudePractice;

const Title = (props) => (
  <Typography fontWeight={700} mb={0.5}>
    {props.children}
  </Typography>
);
