import { useQuery } from "@tanstack/react-query";
import { errorIcon } from "assets/icon/Icon_modal";
import { alertModalConfig } from "components/templates/modalConfig";
import { useState } from "react";
import Swal from "sweetalert2";
import { getHandler } from "../utils/requestURI";
import { hr_uris_GET } from "../utils/uris/hr_uris";

const useGetFile = (
  type = "HR",
  name,
  paramValues,
  enabledOption,
  setEnableOption
) => {
  const [data, setData] = useState(null);
  const { isLoading, isFetching, isError, refetch } = useQuery(
    [name, paramValues],
    (queryKey) => {
      return type === "HR"
        ? hr_uris_GET(name, queryKey)
        : getHandler(name, queryKey);
    },
    {
      retry: 1,
      enabled: enabledOption,
      keepPreviousData: true,
      onSuccess: (res) => {
        setEnableOption(false);
        setData(res);
        window.open(`https://${res.data.data}`);
      },
      onError: (error) => {
        setEnableOption(false);

        Swal.fire(
          alertModalConfig({
            title: "파일 업로드",
            text: error.response.data.message,
            iconHtml: errorIcon,
            confirmButtonText: "확인",
          })
        );
      },
    }
  );

  return { data, isFetching, isLoading, isError, refetch };
};

export default useGetFile;
