import { Box, Typography } from "@mui/material";
export const examMAPtype = (input) => {
  switch (input) {
    case "2":
      return (
        <Box width="60%" mx="auto">
          <Typography
            variant="body2"
            bgcolor="#FBF3DB"
            color="#d09f00"
            py={0.2}
            borderRadius={0.5}
            fontWeight={700}
          >
            인성
          </Typography>
        </Box>
      );
    case "1":
      return (
        <Box width="60%" mx="auto">
          <Typography
            variant="body2"
            bgcolor="#EAE4F2"
            color="#6940A5"
            py={0.2}
            borderRadius={0.5}
            fontWeight={700}
          >
            적성
          </Typography>
        </Box>
      );
    default:
      return (
        <Box width="60%" mx="auto">
          <Typography
            variant="body2"
            bgcolor="#DDEDEA"
            color="#0F7B6C"
            py={0.2}
            borderRadius={0.5}
            fontWeight={700}
          >
            인적성
          </Typography>
        </Box>
      );
  }
};
