/**
 * contendEditable Hook
 * @param initialContent string
 */

import { useEffect, useRef, useState } from "react";

export const useContentEditable = (initialContent) => {
  const $contentEditable = (useRef < HTMLDivElement) | (null > null);
  const [content, _setContent] = useState(initialContent);

  const onInput = (event) => {
    _setContent(event.target.innerText);
  };

  const setContent = (newContent) => {
    if ($contentEditable.current) {
      $contentEditable.current.innerText = newContent;
      _setContent(newContent);
    }
  };

  useEffect(() => {
    setContent(initialContent);
  }, []);

  return { content, setContent, _setContent, onInput, $contentEditable };
};
