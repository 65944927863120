import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { hr_uris_GET } from "utils/uris/hr_uris";

export function useScoreTest(id) {
  const [page, setPage] = useState(1);

  const [scoreTestOn, setScoreTestOn] = useState(false);

  const [progressState, setProgressState] = useState();

  const [errorMessage, setErrorMessage] = useState("");

  const {
    isFetching: scoreTest_isFetching,
    isLoading: scoreTest_isLoading,
    isError: scoreTest_isError,
  } = useQuery(
    ["scoreTest", { groupIdx: Number(id), progressNo: page }],
    (queryKey) => hr_uris_GET("scoreTest", queryKey, false),
    {
      retry: 1,
      enabled: scoreTestOn,
      onSuccess: (res) => {
        if (res?.status === 200) {
          if (res.data.data.totalProgress === page) {
            setScoreTestOn(false);
          } else {
            setPage((prev) => prev + 1);
            setProgressState(res.data.data);
          }
        }
      },
      onError: (error) => {
        setErrorMessage(error.response.data.message);
        setScoreTestOn(false);
      },
    }
  );

  return {
    progressState,
    scoreTestOn,
    setScoreTestOn,
    scoreTest_isFetching,
    scoreTest_isLoading,
    scoreTest_isError,
    errorMessage,
  };
}
