import { useState, useEffect, useRef } from "react";
export function useTimer(start, timer) {
  // set initial state to be 59s
  const [count, setCount] = useState(timer);

  useEffect(() => {
    // given that we passed in the variable when the timer      should start we use it here
    if (start) {
      const secondsLeft = setInterval(() => {
        setCount((c) => c - 1);
      }, 1000);
      return () => clearInterval(secondsLeft);
    }
    // we keep track when to rerender the hook, aka when the start is changed to true
  }, [timer, start]);

  return { count, setCount };
}
