import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { Link, useNavigate } from "react-router-dom";

import useMutationHook from "hooks/useMutationHook";

import { Controller, useForm } from "react-hook-form";

import LOGO from "assets/image/logo/CJ.png";

import BACKGROUND from "assets/image/background/acg_bg.png";
import { REQUIRE_VALUE } from "enums/errorMessage";
import Swal from "sweetalert2";
import { errorIcon } from "assets/icon/Icon_modal";
import { alertModalConfig } from "components/templates/modalConfig";
import { LogIn, RotateCw } from "lucide-react";
import { motion } from "framer-motion";
import { useTimer } from "hooks/useTimer";
import { addZero } from "utils/formatter/dateFormat";

export default function LoginPage() {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      adminAccount: "",
      adminPassword: "",
    },
  });

  const { mutate } = useMutationHook("login");
  const { mutate: login2faMutation } = useMutationHook("login2fa");
  const [showPassword, setShowPassword] = useState(false);
  const [timerStart, setTimerStart] = useState(false);
  const [login2fa, setLogin2fa] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [startTime, setStartTime] = useState(180);

  const { count, setCount } = useTimer(timerStart, startTime);

  const [adminIdx, setAdminIdx] = useState(undefined);

  const authKey = useRef();
  const handleStopTimer = () => {
    setTimerStart(false);
  };
  const handleLogin = (data) => {
    mutate(
      {
        ...getValues(),
      },
      {
        onSuccess: (res) => {
          setAdminIdx(res.data.data);
          setLogin2fa(true);
          setTimerStart(true);
        },
        onError: (res) => {
          Swal.fire(
            alertModalConfig({
              text: res.response.data.message,
              title: "로그인",
              iconHtml: errorIcon,
              confirmButtonText: "확인",
            })
          );
        },
      }
    );
  };

  useLayoutEffect(() => {
    login2fa && authKey.current?.focus();
  }, [login2fa]);
  const handleReTry = () => {
    mutate(
      {
        ...getValues(),
      },
      {
        onSuccess: (res) => {
          setAdminIdx(res.data.data);
          setTimerStart(true);
          setStartTime(180);
          setCount(180);
          authKey.current.focus();
        },
        onError: (res) => {
          Swal.fire(
            alertModalConfig({
              text: res.response.data.message,
              title: "로그인",
              iconHtml: errorIcon,
              confirmButtonText: "확인",
            })
          );
        },
      }
    );
  };
  const handleLogin2fa = () => {
    login2faMutation(
      {
        adminIdx: adminIdx,
        authKey: authKey.current.value,
      },
      {
        onSuccess: (res) => {
          setLogin2fa(undefined);
          localStorage.setItem("SUPER", res.data.data.loginToken);
          navigate("/company", { replace: true });
        },
        onError: (res) => {
          Swal.fire(
            alertModalConfig({
              text: res.response.data.message,
              title: "로그인",
              iconHtml: errorIcon,
              confirmButtonText: "확인",
            })
          );
        },
      }
    );
  };

  const psRef = useRef(null);

  const handleKeyPress = (e, name) => {
    const values = getValues();
    if (e.key === "Enter") {
      if (name === "sendKey") {
        handleLogin(values);
        psRef.current.blur();
      } else if (name === "login") {
        handleLogin2fa();
      }
    }
  };

  const isMobile = useMediaQuery("(max-width:500px)");
  useEffect(() => {
    if (count < 1) {
      handleStopTimer();
    }
  }, [count, timerStart, startTime]);
  return (
    <Container maxWidth={false} disableGutters sx={bgContainer}>
      <Grid
        width={isMobile ? "25rem" : "30rem"}
        container
        p={4}
        justifyContent="center"
        bgcolor={"rgba( 255, 255, 255, 0.9 )"}
        boxShadow={"0 5px 15px 0 #d2d2d2"}
        borderRadius={2}
        textAlign="center"
        border={"1px solid rgba( 255, 255, 255, 0.18 )"}
      >
        <form style={{ width: "100%" }}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item xs>
              <img src={LOGO} style={{ width: 100, heigh: 80 }} />
              {/* <LOGO style={{ width: 100, height: 80 }} /> */}
            </Grid>
            <Grid item>
              <Typography fontWeight={800} variant="h5">
                [CJ] ACG Admin
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="adminAccount"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: REQUIRE_VALUE,
                  },
                }}
                render={({ field }) => (
                  <TextField {...field} fullWidth error={errors.adminAccount && true} label={"아이디 입력"} color={"neutral"} />
                )}
              />
              <Typography color={"red"} variant="body2" textAlign={"right"}>
                {errors.adminAccount?.type === "required" ? errors.adminAccount.message : null}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel>비밀번호 입력</InputLabel>
                <Controller
                  name="adminPassword"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRE_VALUE,
                    },
                  }}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      type={showPassword ? "text" : "password"}
                      error={errors.adminPassword && true}
                      onKeyUp={(e) => handleKeyPress(e, "sendKey")}
                      inputRef={psRef}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="비밀번호 입력"
                    />
                  )}
                />
              </FormControl>
              <Typography color={"red"} variant="body2" textAlign={"right"}>
                {errors.adminPassword?.type === "required" ? errors.adminPassword.message : null}
              </Typography>
            </Grid>

            {login2fa ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}
                style={{ width: "100%", marginTop: 20 }}
              >
                <Grid container columnGap={1}>
                  <Grid item xs>
                    <OutlinedInput
                      placeholder={timerStart ? "인증번호를 입력해 주세요." : "인증번호를 재요청해 주세요."}
                      size="small"
                      fullWidth
                      inputRef={authKey}
                      sx={{
                        padding: 0,
                        "& input": {
                          width: "70%",
                        },
                        color: "#444444",
                      }}
                      onKeyUp={(e) => handleKeyPress(e, "login")}
                      endAdornment={`${addZero(parseInt((count % 3600) / 60))} : ${addZero(count % 60)}`}
                    />
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" sx={{ height: "100%" }} onClick={handleReTry} startIcon={<RotateCw size={15} />}>
                      재요청
                    </Button>
                  </Grid>
                </Grid>
              </motion.div>
            ) : // <Grid
            //   item
            //   container
            //   alignItems="center"
            //   justifyContent={"space-between"}
            //   columnGap={1}
            // >
            //   <Grid item xs={5.5}>
            //     <Button
            //       fullWidth
            //       variant="outlined"
            //       startIcon={<UserPlus size={14} />}
            //       onClick={() => {
            //         Swal.fire(
            //           alertModalConfig({
            //             text: "ACG 담당자에게 문의해 주시기 바랍니다.",
            //             title: "회원가입",
            //             iconHtml: warningIcon,
            //             confirmButtonText: "확인",
            //           })
            //         );
            //       }}
            //     >
            //       회원가입
            //     </Button>
            //   </Grid>
            //   <Grid item xs={5.5}>
            //     <Button
            //       fullWidth
            //       variant="outlined"
            //       startIcon={<KeyRound size={14} />}
            //       onClick={() => {
            //         Swal.fire(
            //           alertModalConfig({
            //             text: "ACG 담당자에게 문의해 주시기 바랍니다.",
            //             title: "비밀번호 찾기",
            //             iconHtml: warningIcon,
            //             confirmButtonText: "확인",
            //           })
            //         );
            //       }}
            //     >
            //       비밀번호 찾기
            //     </Button>
            //   </Grid>
            // </Grid>
            null}
            <Grid item xs={12}>
              {login2fa ? (
                <Button
                  fullWidth
                  variant={"contained"}
                  onClick={handleLogin2fa}
                  sx={{ "&": { fontSize: 13, fontWeight: 700 } }}
                  startIcon={<LogIn size={15} />}
                >
                  로그인
                </Button>
              ) : (
                <Button
                  fullWidth
                  color={"secondary"}
                  variant={"contained"}
                  onClick={handleSubmit(handleLogin)}
                  sx={{ "&": { fontSize: 13, fontWeight: 700 } }}
                >
                  인증하기
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Container>
  );
}

const bgContainer = {
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bgcolor: "#f1f1f1",
  // background: `url(${BACKGROUND})  no-repeat center`,
  // backgroundSize: "cover",
};
