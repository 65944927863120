import React, { Suspense, useCallback, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import "question.css";
import { questionPageDetailAtom, questionsDetailAtom } from "recoil/store";
import { useRecoilState, useRecoilValue } from "recoil";

import useMutationHook from "hooks/useMutationHook";
import { useQueryClient } from "@tanstack/react-query";

import ReactQuill from "react-quill";
import { MESSAGE_DELETE_QUESTION_GROUP } from "enums/modalMessage";
import NoneInfoByPage from "components/pages/acg_admin/practice/none/NoneInfoByPage";
import LoadingCircle from "components/UI/organism/LoadingCircle";
import EditQuestionAptitude from "./EditQuestionAptitude";
import ExListNumberBtn from "components/UI/ExListNumberBtn";
import Swal from "sweetalert2";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import { Settings, Trash2 } from "lucide-react";
import { Button, Stack } from "@mui/material";

const QuestionAptitude = ({ tabId }) => {
  const queryClient = useQueryClient();

  const question1st = useRecoilValue(questionsDetailAtom);
  const question2nd = useRecoilValue(questionPageDetailAtom);

  //* 예제 문항 삭제 Mutation
  const { mutate } = useMutationHook("deleteOneQuestion");

  const [targetItem, setTargetItem] = useState({});
  const [updateQuestionModalOn, setUpdateQuestionModalOn] = useState(false);

  const handleUpdateQuestionModal = (e, item) => {
    setTargetItem(item);
    setUpdateQuestionModalOn(!updateQuestionModalOn);
  };
  const exListRender = (practiceExList = []) => {
    return practiceExList.map((ex, index) => {
      return (
        <Grid
          container
          key={ex.questionExNo}
          alignItems="flex-start"
          flexWrap="noWrap"
          pl={"4px"}
          mb={"16px"}
        >
          <Grid item mr={"15px"}>
            <ExListNumberBtn index={index} ex={ex} />
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              columnGap={1}
              alignItems="center"
              flexWrap={"wrap"}
              rowGap={1}
            >
              <Typography
                m={0}
                fontSize="18px"
                sx={{ whiteSpace: "pre-wrap", "&>p": { m: 0 } }}
                dangerouslySetInnerHTML={{
                  __html: ex.questionExText,
                }}
              ></Typography>
              <Typography>(배점 : {ex.answerScore}점)</Typography>
            </Stack>
          </Grid>
        </Grid>
      );
    });
  };

  //* Mutation 후 이벤트
  const alertResult = useCallback((key, key2) => {
    queryClient.invalidateQueries({
      queryKey: key,
    });
    queryClient.invalidateQueries({
      queryKey: key2,
    });
  }, []);

  const handleDeleteQuestionDetail = (_, practice) => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DELETE_QUESTION_GROUP,
        title: "문항 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          {
            questionIdx: question1st.questionIdx,
            questionNo: practice.questionNo,
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 삭제가 완료되었습니다.",
                  title: "문항 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed)
                  alertResult("questionDetail2", "questionDetail1");
              });
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 삭제를 실패하였습니다.",
                  title: "문항 삭제",
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
      }
    });
  };

  if (question2nd && question2nd.questionList.length < 1)
    return <NoneInfoByPage />;

  return (
    <Grid
      overflow="auto"
      container
      direction={"column"}
      rowGap={5}
      width={1061}
      mx="auto"
      mt={3}
      height="100%"
    >
      {question2nd &&
        question2nd.questionList.map((practice, index) => {
          return (
            <Grid
              key={index}
              container
              direction="column"
              alignItems="flex-start"
            >
              <Grid
                item
                container
                justifyContent={"space-between"}
                alignItems="flex-start"
                mb="1rem"
              >
                <Grid
                  item
                  xs
                  container
                  flexWrap="nowrap"
                  alignItems="flex-start"
                >
                  <Typography
                    lineHeight={1.8}
                    mr={1}
                    sx={{
                      fontSize: "18px",
                    }}
                  >{`${practice.questionNo}.`}</Typography>
                  <ReactQuill
                    theme="bubble"
                    readOnly
                    value={practice?.questionText}
                    className="questionText-readOnly"
                  />
                </Grid>

                <Grid item>
                  <Grid item container columnGap={1}>
                    <Tooltip title="문항 수정" arrow placement="top">
                      <Box component="span">
                        <Button
                          startIcon={<Settings size={15} />}
                          variant="outlined"
                          disableElevation
                          onClick={(e) =>
                            handleUpdateQuestionModal(e, practice)
                          }
                          sx={{ "&": { color: "#424242" } }}
                        >
                          수정
                        </Button>
                      </Box>
                    </Tooltip>
                    <Tooltip title="문항 삭제" arrow placement="top">
                      <Box component="span">
                        <Button
                          startIcon={<Trash2 size={15} />}
                          variant="contained"
                          color="removeButton"
                          disableElevation
                          onClick={(e) =>
                            handleDeleteQuestionDetail(e, practice)
                          }
                          sx={{ "&": { color: "#c42c08" } }}
                        >
                          삭제
                        </Button>
                      </Box>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>

              {practice.questionSubText === null ||
              practice.questionSubText === "" ? null : (
                <ReactQuill
                  theme="bubble"
                  readOnly
                  value={practice?.questionSubText}
                  className={
                    practice?.questionSubText?.includes("<img")
                      ? "questionSubText-readOnly imgQuill"
                      : "questionSubText-readOnly textQuill"
                  }
                  style={{
                    background: " rgb(240, 240, 240)",
                    borderRadius: 0,
                    width: "100%",
                    whiteSpace: "pre-wrap",
                    marginBottom: "24px",
                  }}
                />
              )}
              <Grid container>{exListRender(practice?.questionExList)}</Grid>
            </Grid>
          );
        })}
      <Dialog
        maxWidth="md"
        fullWidth
        open={updateQuestionModalOn}
        onClose={handleUpdateQuestionModal}
      >
        <Suspense fallback={<LoadingCircle />}>
          <EditQuestionAptitude
            onClose={handleUpdateQuestionModal}
            targetItem={targetItem}
            pageNo={tabId + 1}
            practiceType={{
              likertYN: question1st && question1st.likertYN,
              ipsativeYN: question1st && question1st.ipsativeYN,
            }}
          />
        </Suspense>
      </Dialog>
    </Grid>
  );
};

export default QuestionAptitude;
