import { useEffect } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { EXAM_MAP_0, EXAM_MAP_1, EXAM_MAP_2 } from "enums/examMAP";
import "flatpickr/dist/themes/airbnb.css";
import { Search } from "lucide-react";
import { useRecoilValue } from "recoil";
import { HRcompanyInfo } from "recoil/store";

export default function SearchGroupList({
  handleSearchCondition,
  submitSearch,
  searchRef,
  searchCondition,
  placeholder,
  filterCondition,
  setFilterCondition,
  isPilot = false,
}) {
  const companyInfo = useRecoilValue(HRcompanyInfo);
  const handleSubmitValues = (e) => {
    const { name, value } = e.target;
    setFilterCondition((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    searchRef.current.focus();
  }, []);
  const isCheckedFilter = () =>
    Object.values(filterCondition).some((item) => item !== "");
  return (
    <Grid container columnGap={1}>
      {/* {companyInfo.companyIdx !== 1 || isPilot ? null : (
        <TextField
          select
          label="확정여부"
          size="small"
          name="approveYN"
          onChange={handleSubmitValues}
          value={filterCondition?.approveYN}
          sx={{ minWidth: 80 }}
          children={GROUP_CONFIRM_STATUS.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        />
      )} */}
      {/* <TextField
        select
        label="진행 현황"
        size="small"
        name="groupStatus"
        onChange={handleSubmitValues}
        value={filterCondition?.groupStatus}
        sx={{ minWidth: 120 }}
        children={GROUP_STATUS.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      /> */}

      <TextField
        select
        label="감독 방식"
        size="small"
        name="roomUseYN"
        onChange={handleSubmitValues}
        value={filterCondition?.roomUseYN}
        sx={{ minWidth: 100 }}
      >
        <MenuItem key={"total"} value={"TOTAL"}>
          전체
        </MenuItem>
        <MenuItem key={"Y"} value={"Y"}>
          화상감독
        </MenuItem>
        <MenuItem key={"N"} value={"N"}>
          무감독
        </MenuItem>
      </TextField>
      <TextField
        select
        label="검사유형"
        size="small"
        name="examMAP"
        onChange={handleSubmitValues}
        value={filterCondition?.examMAP}
        sx={{ minWidth: 100 }}
      >
        <MenuItem key={"total"} value={"TOTAL"}>
          전체
        </MenuItem>
        <MenuItem key={"0"} value={"0"}>
          {EXAM_MAP_0}
        </MenuItem>
        <MenuItem key={"2"} value={"2"}>
          {EXAM_MAP_2}
        </MenuItem>
        <MenuItem key={"1"} value={"1"}>
          {EXAM_MAP_1}
        </MenuItem>
      </TextField>

      {/* <Flatpickr
        className={"datepickerInput"}
        value={[filterCondition?.sExamDate, filterCondition?.eExamDate]}
        placeholder="검사 기간을 선택해주세요."
        options={{
          locale: Korean,
          mode: "range",
        }}
        onChange={([dateFrom, dateTo]) => {
          if (dateFrom?.getTime() === dateTo?.getTime()) {
            let days = 1 * 24 * 60 * 60 * 1000;
            dateTo.setTime(dateTo.getTime() + days);
            setFilterCondition((prev) => ({ ...prev, sExamDate: dateFrom }));
            setFilterCondition((prev) => ({ ...prev, eExamDate: dateTo }));
          } else {
            setFilterCondition((prev) => ({ ...prev, sExamDate: dateFrom }));
            setFilterCondition((prev) => ({ ...prev, eExamDate: dateTo }));
          }
        }}
      /> */}

      <Grid item>
        <TextField
          size="small"
          inputRef={searchRef}
          sx={{ minWidth: 300 }}
          placeholder="공고명 또는 검사시작 날짜를 입력해주세요"
          onKeyDown={(e) => {
            if (e.key === "Enter") submitSearch();
          }}
        />
      </Grid>
      <Grid item>
        <Button
          startIcon={<Search size={15} />}
          sx={{ height: "100%" }}
          variant={"outlined"}
          onClick={submitSearch}
        >
          검색
        </Button>
      </Grid>
    </Grid>
  );
}
