import React, { lazy, Suspense, useCallback, useRef, useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";

import { useNavigate } from "react-router-dom";
import useGetQuery from "hooks/useGetQuery";
import { dateFormat } from "utils/formatter/dateFormat";

import { iconBtnTheme, tableHeader, tableWrapper } from "themes/theme";
import { MESSAGE_DELETE_QUESTION_GROUP } from "enums/modalMessage";
import { useQueryClient } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { isQuestionDetailPage, modalAtom } from "recoil/store";
import useMutationHook from "hooks/useMutationHook";
import { QUESTION_GROUP_LIST } from "enums/tableColumn";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import { useEffect } from "react";
import { calcRowNum } from "utils/calcRowNum";
import { checkboxSelect } from "utils/checkboxSelect";
import { practiceType } from "utils/practiceType";
import { Chip } from "@mui/material";
import Swal from "sweetalert2";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import TopTitle from "components/templates/topTitle/TopTitle";
import { Plus, Settings, Trash2 } from "lucide-react";

const CreateQuestionGroup = lazy(() => import("./CreateQuestionGroup"));
const UpdateQuestionGroup = lazy(() => import("./UpdateQuestionGroup"));

const QuestionGroupMain = () => {
  const setIsQuestionDetail = useSetRecoilState(isQuestionDetailPage);
  const queryClient = useQueryClient();
  const questionNameRef = useRef();
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(20);
  const [selected, setSelected] = useState([]);
  const [createQuestionGroupModalOn, setCreateQuestionGroupModalOn] =
    useState(false);
  const [updateQuestionGroupModalOn, setUpdateQuestionGroupModalOn] =
    useState(false);
  const [selectTargetInfo, setSelectTargetInfo] = useState({});

  const handleCheckClick = (_, name) => {
    const { questionIdx } = name;
    const selectedIndex = selected.indexOf(questionIdx);
    setSelected(checkboxSelect(selected, selectedIndex, questionIdx));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleUpdateQuestionGroupModal = (_, info) => {
    setUpdateQuestionGroupModalOn(!updateQuestionGroupModalOn);
    setSelectTargetInfo(info);
  };
  const handleCreateQuestionGroupModal = () =>
    setCreateQuestionGroupModalOn(!createQuestionGroupModalOn);
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);
  const [paramValues, setParamValues] = useState({
    pageNo: 1,
  });
  //* QUERY
  const { data, pageInfo, isLoading, isFetching, isError, refetch } =
    useGetQuery("getTotalQuestion", paramValues, true, false);
  //* 문항그룹 삭제 Mutation
  const { mutate } = useMutationHook("deleteQuestionGroup");

  const handleDeletePracticeGroup = (_, practice) => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DELETE_QUESTION_GROUP,
        title: "문항 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          {
            questionIdx: selected[0],
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 삭제가 완료되었습니다.",
                  title: "문항 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("getTotalQuestion");
              });
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  text: error.response.data.message,
                  title: "문항 삭제",
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
      }
    });
  };

  const handleSearch = (e, name) => {
    if (name === "click") {
      setParamValues((prev) => ({
        ...prev,
        questionName: questionNameRef.current.value,
      }));
    } else {
      if (e.key === "Enter") {
        setParamValues((prev) => ({
          ...prev,
          questionName: questionNameRef.current.value,
        }));
      }
    }
  };

  const handlePerPage = (e) => {
    setPerPage(e.target.value);
    setParamValues((prev) => ({
      ...prev,
      perPage: e.target.value,
    }));
  };

  useEffect(() => {
    setIsQuestionDetail(false);
    questionNameRef.current.focus();
  }, []);

  return (
    <>
      <Grid
        width="100%"
        height="100%"
        borderRadius={2}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <TopTitle
          list={[
            {
              title: "문항 관리",
              url: `/question`,
              current: true,
            },
          ]}
        />

        <Paper
          sx={{
            ...tableWrapper,
            overflowY: "hidden",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: 2,
            p: 2,
            rowGap: 2,
          }}
          variant="outlined"
        >
          <Box>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Stack direction={"row"} columnGap={1}>
                <Select
                  size="small"
                  defaultValue={perPage}
                  onChange={handlePerPage}
                >
                  <MenuItem key={10} value={10}>
                    10/Page
                  </MenuItem>
                  <MenuItem key={20} value={20}>
                    20/Page
                  </MenuItem>
                  <MenuItem key={50} value={50}>
                    50/Page
                  </MenuItem>
                </Select>
                <TextField
                  size="small"
                  placeholder="문항명을 입력하세요"
                  sx={{ minWidth: 300 }}
                  inputRef={questionNameRef}
                  onKeyUp={(e) => handleSearch(e, "enter")}
                />
                <Button
                  variant="contained"
                  onClick={(e) => handleSearch(e, "click")}
                >
                  검색
                </Button>
              </Stack>
              <Stack direction="row" columnGap={1}>
                <Tooltip title="예제 추가" arrow placement="top">
                  <Box component="span">
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={handleCreateQuestionGroupModal}
                      startIcon={<Plus size={15} />}
                    >
                      문항 등록
                    </Button>
                  </Box>
                </Tooltip>
                <Tooltip title="문항삭제는 1개씩만 가능합니다." arrow>
                  <Box component={"span"}>
                    <Button
                      startIcon={<Trash2 size={15} />}
                      variant="contained"
                      color="removeButton"
                      disableElevation
                      disabled={selected.length > 1 || selected.length < 1}
                      onClick={handleDeletePracticeGroup}
                      sx={{ "&": { color: "#c42c08" } }}
                    >
                      선택항목 삭제
                    </Button>
                  </Box>
                </Tooltip>
              </Stack>
            </Grid>
          </Box>

          <TableContainer sx={{ height: "calc(95% - 20px)" }}>
            <Table stickyHeader sx={tableHeader} size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="none" align="center" sx={{ width: 5 }}>
                    <Checkbox size="small" />
                  </TableCell>
                  {QUESTION_GROUP_LIST.map((item) => {
                    return item.flag === false ? null : (
                      <TableCell
                        padding="none"
                        key={item.label}
                        sx={{ width: item.size, fontWeight: "bold" }}
                        align="center"
                      >
                        {item.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.data?.length < 1 ? (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell colSpan={17} height={300}>
                      <EmptyPlaceholder message={"조회내용이 없습니다."} />
                    </TableCell>
                  </TableRow>
                ) : (
                  data &&
                  data.data?.map((row, index) => (
                    <TableRow
                      hover
                      key={row.questionIdx}
                      sx={{
                        "&": { height: 45, cursor: "pointer" },
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <TableCell padding="none" align="center">
                        <Checkbox
                          size="small"
                          color="primary"
                          onClick={(event) => handleCheckClick(event, row)}
                          checked={isSelected(row.questionIdx)}
                        />
                      </TableCell>
                      <TableCell
                        padding="normal"
                        align="center"
                        onClick={() =>
                          navigate(`/question/${row.questionIdx}`, {
                            state: { item: row },
                          })
                        }
                      >
                        {calcRowNum(index, pageInfo, perPage)}
                      </TableCell>

                      <TableCell
                        padding="none"
                        align="center"
                        onClick={() =>
                          navigate(`/question/${row.questionIdx}`, {
                            state: { item: row },
                          })
                        }
                      >
                        {practiceType(row) === "APTITUDE" ? (
                          <Chip
                            label="적성"
                            variant="outlined"
                            size="small"
                            sx={{
                              "&": { border: "0.6px solid #2571ff", px: 1 },
                              "&>span": {
                                fontWeight: 700,
                                color: "#00328e",
                              },
                            }}
                          />
                        ) : (
                          <Chip
                            label="인성"
                            variant="outlined"
                            size="small"
                            sx={{
                              "&": { border: "0.6px solid tomato", px: 1 },
                              "&>span": {
                                fontWeight: 700,
                                color: "tomato",
                              },
                            }}
                          />
                        )}
                      </TableCell>

                      <TableCell
                        padding="none"
                        align="center"
                        onClick={() =>
                          navigate(`/question/${row.questionIdx}`, {
                            state: { item: row },
                          })
                        }
                      >
                        {row.questionMemo}
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        sx={{ position: "relative" }}
                        onClick={() =>
                          navigate(`/question/${row.questionIdx}`, {
                            state: { item: row },
                          })
                        }
                      >
                        {row.questionName}
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        onClick={() =>
                          navigate(`/question/${row.questionIdx}`, {
                            state: { item: row },
                          })
                        }
                      >
                        {row.totalQuestion}
                      </TableCell>

                      <TableCell
                        padding="none"
                        align="center"
                        onClick={() =>
                          navigate(`/question/${row.questionIdx}`, {
                            state: { item: row },
                          })
                        }
                      >
                        {dateFormat(new Date(row.createdAt))}
                      </TableCell>
                      <TableCell padding="none" align="center">
                        <IconButton
                          size="small"
                          onClick={(e) =>
                            handleUpdateQuestionGroupModal(e, row)
                          }
                          sx={{
                            border: "1px solid #ddd9d996",
                            borderRadius: 2,
                          }}
                        >
                          <Settings size={15} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container alignItems={"center"} justifyContent="center">
            <Pagination
              size="small"
              count={pageInfo.totalPage}
              page={pageInfo.pageNo}
              onChange={(_, page) => {
                setParamValues((prev) => ({
                  ...prev,
                  pageNo: page,
                }));
              }}
            />
          </Grid>
        </Paper>

        <Dialog
          maxWidth={"xs"}
          fullWidth
          open={updateQuestionGroupModalOn}
          onClose={handleUpdateQuestionGroupModal}
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <UpdateQuestionGroup
              onClose={handleUpdateQuestionGroupModal}
              selectTargetInfo={selectTargetInfo}
            />
          </Suspense>
        </Dialog>
        <Dialog
          maxWidth={"xs"}
          fullWidth
          open={createQuestionGroupModalOn}
          onClose={handleCreateQuestionGroupModal}
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <CreateQuestionGroup onClose={handleCreateQuestionGroupModal} />
          </Suspense>
        </Dialog>
      </Grid>
    </>
  );
};

export default QuestionGroupMain;
