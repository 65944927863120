import { useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Link, useNavigate, useParams } from "react-router-dom";

import useMutationHook from "hooks/useMutationHook";

import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import LOGO from "assets/image/logo/CJ.png";
import { modalWrapper } from "components/templates/modalConfig";
import MenuComponent from "components/UI/menu/MenuComponent";
import { DrawerHeader } from "components/UI/nav/DrawerHeader";
import { ClipboardCheck, UserSquare } from "lucide-react";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { HRcompanyInfo, isNavOpen } from "recoil/store";

export default function NavHR() {
  const navigate = useNavigate();
  const isOpen = useRecoilValue(isNavOpen);
  const companyInfo = useRecoilValue(HRcompanyInfo);
  const { company } = useParams();

  const refreshContent = () => {
    navigate(`/acghr_hr/${company}/stats`);
    navigate(0);
  };

  const { mutate } = useMutationHook("logoutHR");

  const afterConfirm = () => {
    sessionStorage.clear();
    localStorage.removeItem(company);
    navigate("/login_user");
  };

  const handleLogout = () => {
    modalWrapper({
      text: "로그아웃 하시겠습니까?",
      title: "로그아웃",
      icon: "question",
      func: mutate,
      successText: "로그아웃 되었습니다.",
      afterConfirmAction: afterConfirm,
    });
  };

  useEffect(() => {
    document.title = `CJ CFT - 통계`;
  }, []);

  const handleMoveEditUser = () => {
    navigate(`/acghr_hr/${company}/stats/updateUser`);
  };
  const [menuIsOpen, setMenuIsOpen] = useState({
    0: true,
  });

  const handleClickMenu = (_, index) => {
    setMenuIsOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  return (
    <>
      <Drawer
        variant="persistent"
        anchor={"left"}
        open={isOpen}
        sx={{
          width: 260,
          flexShrink: 0,

          [`& .MuiDrawer-paper`]: {
            width: 260,
            boxSizing: "border-box",
            border: "none",
            padding: "0px 20px 0px 30px",
            backgroundColor: "#f8f9fa",
          },
        }}
      >
        <Link onClick={refreshContent}>
          <DrawerHeader logo={LOGO} />
        </Link>

        <Box mt={3} backgroundColor={"white"} borderRadius={2}>
          <Grid container alignItems={"center"} my={2} justifyContent="center">
            <MenuComponent
              icon={<MenuRoundedIcon />}
              list={[
                { title: "개인정보수정", function: handleMoveEditUser },
                {
                  title: "로그아웃",
                  function: handleLogout,
                  sx: { color: "red" },
                },
              ]}
            />
            <Typography sx={{ wordBreak: "keep-all" }} fontWeight={700}>
              {companyInfo?.userName} 님
            </Typography>
            <Typography>, 안녕하세요 🙇‍♂️</Typography>
          </Grid>
          <Divider />
          <List>
            <ListItemButton onClick={(e) => handleClickMenu(e, 0)}>
              <ListItemText
                primary="검사/운영"
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
              {menuIsOpen[0] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={menuIsOpen[0]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to={`/acghr_hr/${company}/stats`} key={0} reloadDocument>
                  <ListItemButton sx={{ pl: 4 }} key={0}>
                    <ListItemIcon sx={{ minWidth: "36px" }}>
                      <ClipboardCheck size={16} strokeWidth={1.8} />
                    </ListItemIcon>
                    <ListItemText children="채용그룹 관리" />
                  </ListItemButton>
                </Link>
                <Link
                  to={`/acghr_hr/${company}/stats/manager`}
                  key={1}
                  reloadDocument
                >
                  <ListItemButton sx={{ pl: 4 }} key={1}>
                    <ListItemIcon sx={{ minWidth: "36px" }}>
                      <UserSquare size={16} strokeWidth={1.8} />
                    </ListItemIcon>
                    <ListItemText children="담당자 관리" />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </List>

          {/* <NavMenuList menuListTitle={menuListTitle_STATS} /> */}
        </Box>
      </Drawer>
    </>
  );
}
