import React, { lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Content from "./Content";
import LoginPage from "components/pages/login/LoginPage";
import LoginPage_hr from "components/pages/login/LoginPage_hr";
import UpdateUser from "components/pages/signUp/HR/user/UpdateUser";

import {
  companyRoutes,
  groupRoutes,
  HRManageRoutes,
  qnaRoutes,
} from "utils/routers";
import PracticeMain from "components/pages/acg_admin/practice/PracticeGroupMain";
import PracticeDetail from "components/pages/acg_admin/practice/PracticeDetail";
import QuestionGroupMain from "components/pages/acg_admin/question/QuestionGroupMain";
import QuestionDetail from "components/pages/acg_admin/question/detail/QuestionDetail";
import SubjectGroupMain from "components/pages/acg_admin/subject/SubjectGroupMain";
import SubjectDetail from "components/pages/acg_admin/subject/SubjectDetail";
import Stats from "components/pages/stats/Stats";
import ManagerList from "components/pages/stats/ManagerList";
import TestersList from "components/pages/stats/TestersList";
import ContentStats from "./ContentStats";
import DetailStatsPage from "components/pages/stats/DetailStatsPage";
import LoginProgress from "components/pages/login/LoginProgress";
import SignUpHR from "components/pages/signUp/HR/signUpPages/SignUpHR";

const SignUpSUPER = lazy(() =>
  import("components/pages/signUp/SUPER/SignUpSUPER")
);
const SearchTesters = lazy(() =>
  import("components/pages/acg_hr/hr/SearchTesters")
);
const ManageTerms = lazy(() =>
  import("components/pages/acg_admin/terms/ManageTerms")
);
const ReportList = lazy(() =>
  import("components/pages/acg_admin/report/ReportList")
);
const SignUpHRterm = lazy(() =>
  import("components/pages/signUp/HR/signUpPages/SignUpTerm")
);

const NotFound = lazy(() => import("components/pages/error/notFound/NotFound"));
const ErrorPage = lazy(() =>
  import("components/pages/error/networkError/ErrorPage")
);
const MiddleRoute = () => {
  const location = useLocation();
  return (
    <Routes key={location.pathname}>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/login_user" element={<LoginPage_hr />} />
      <Route path="/login_hr" element={<LoginProgress />} />
      <Route path="/signUp_hr" element={<SignUpHR />} />
      <Route path="/signUp_hr_term" element={<SignUpHRterm />} />
      <Route path="/signUp_super" element={<SignUpSUPER />} />
      <Route path="/" element={<Navigate replace to="/login" />} />
      {/* 통계 */}
      <Route element={<ContentStats />}>
        <Route path="/acghr_hr/:company/stats" element={<Stats />} />
        <Route
          path="/acghr_hr/:company/stats/manager"
          element={<ManagerList />}
        />
        <Route
          path="/acghr_hr/:company/stats/:id"
          element={<DetailStatsPage />}
        />
        <Route
          path="/acghr_hr/:company/stats/updateUser"
          element={<UpdateUser />}
        />
        <Route
          path="/acghr_hr/:company/stats/testers/:id"
          element={<TestersList />}
        />
      </Route>
      {/* //*ACG */}
      <Route element={<Content />}>
        {qnaRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        {companyRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        <Route path="/terms" element={<ManageTerms />} />
        <Route path="/report" element={<ReportList />} />
        <Route path="/practice" element={<PracticeMain />} />
        <Route path="/practice/:id" element={<PracticeDetail />} />
        <Route path="/question" element={<QuestionGroupMain />} />
        <Route path="/question/:id" element={<QuestionDetail />} />
        <Route path="/subject" element={<SubjectGroupMain />} />
        <Route path="/subject/:id" element={<SubjectDetail />} />

        {groupRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        {HRManageRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        <Route
          path="/acghr_hr/:company/searchTester"
          element={<SearchTesters />}
        />

        <Route path="/acghr_hr/:company/updateUser" element={<UpdateUser />} />
      </Route>
      <Route path="*" element={<NotFound />} />
      <Route path="/error" element={<ErrorPage />} />
    </Routes>
  );
};

export default MiddleRoute;
