export const qnaCategory = (input) => {
  switch (input) {
    case "AROUND":
      return "주변기기";
    case "LOGIN":
      return "로그인/접속";
    case "PROGRAM":
      return "프로그램";
    case "ID":
      return "신분증";
    default:
      return "기타";
  }
};
