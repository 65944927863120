import {
  checkIcon,
  errorIcon,
  questionIcon,
  warningIcon,
} from "assets/icon/Icon_modal";
import Swal from "sweetalert2";

export const alertModalConfig = ({ ...props }) => {
  const ElementLength = document.querySelectorAll(".MuiDialog-paper").length;
  const targetClass =
    document.querySelectorAll(".MuiDialog-paper")[ElementLength - 1];
  return {
    // iconHtml: iconHtml,
    customClass: {
      icon: "no-border",
    },
    confirmButtonColor: "#3b3b3b",
    target: targetClass && targetClass,
    ...props,
  };
};

export const confirmModalConfig = ({
  text,
  title,
  iconHtml,
  confirmButtonText,
  cancelButtonText,
  target,
  ...props
}) => {
  const ElementLength = document.querySelectorAll(".MuiDialog-paper").length;
  const targetClass =
    document.querySelectorAll(".MuiDialog-paper")[ElementLength - 1];
  return {
    iconHtml: iconHtml,
    title: title,
    text: text,
    showCancelButton: true,
    confirmButtonColor: "#1E283D",
    cancelButtonColor: "#fff",
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    customClass: {
      icon: "no-border",
    },
    target: targetClass && targetClass,
    // target: "body",
    didOpen: () => {
      document.querySelector(".swal2-confirm").focus();
    },
    ...props,
  };
};

export const modalWrapper = ({
  text,
  title,
  icon,
  func = null,
  successText,
  afterConfirmAction,
}) => {
  const iconRender = (icon) => {
    if (icon === "question") {
      return questionIcon;
    } else if (icon === "warning") {
      return warningIcon;
    } else if (icon === "error") {
      return errorIcon;
    }
  };

  const funcAction = (func) => {
    if (func) {
      func(null, {
        onSuccess: () => {
          Swal.fire(
            alertModalConfig({
              text: successText,
              title: title,
              iconHtml: checkIcon,
              confirmButtonText: "확인",
            })
          ).then((result) => {
            if (result.isConfirmed) {
              afterConfirmAction();
            }
          });
        },
      });
    } else {
      afterConfirmAction();
    }
  };

  return Swal.fire(
    confirmModalConfig({
      text: text,
      title: title,
      iconHtml: iconRender(icon),
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    })
  ).then((result) => {
    if (result.isConfirmed) {
      funcAction(func);
    }
  });
};
