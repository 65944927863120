import React, { useCallback, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import styled from "@emotion/styled";
import { Stack } from "@mui/system";
import { blueGrey } from "@mui/material/colors";

import ColorPickerBtn from "components/UI/organism/ColorPickerBtn";
import UploadBtn from "components/UI/organism/UploadBtn";

import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilState, useSetRecoilState } from "recoil";
import { isLayoutPage, modalAtom } from "recoil/store";

import { useQueryClient } from "@tanstack/react-query";
import useMutationHook from "hooks/useMutationHook";

import { LoginTemplate } from "../layoutTemplates/LoginTemplate";
import { TEMPLATE_IMG } from "enums/templateLayouts";
import { objectToRgba, rgbaToObject } from "utils/rgbaFormatter";
import { MESSAGE_UPDATE_LAYOUT } from "enums/modalMessage";
import Swal from "sweetalert2";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";

const drawerWidth = 470;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  flexGrow: 1,
  height: "100vh",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

const defaultValues = {
  layoutIdx: 1,
  ciUseYN: "N",
  ciFilePath: "검사이름",
  mainColor: "#3B3B3B",
  subColor: "#D2D3D4",
  buttonColor: "#131313",
  frameColor: { r: 239, g: 239, b: 239, a: 1 },
  titleText: "",
  titleColor: "#777777",
  bgImageUseYN: "N",
  bgColor: "#FEFEFE",
};

const Title = (props) => <Typography fontWeight={700}>{props.children}</Typography>;
const TemplateMain = () => {
  const setIsLayout = useSetRecoilState(isLayoutPage);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [submitValues, setSubmitValues] = useState(defaultValues);
  const queryClient = useQueryClient();

  const location = useLocation();
  const navigate = useNavigate();

  //* 제목
  const handleTitle = (e) => setSubmitValues((prev) => ({ ...prev, titleText: e.target.value }));

  //* LOGO_USE_YN
  const handleIsLogoUse = (e) => setSubmitValues((prev) => ({ ...prev, ciUseYN: e.target.value }));

  //* 배경 선택 : 색 OR 이미지
  const handleIsBgSelected = (e) => {
    setSubmitValues((prev) => ({
      ...prev,
      bgImageUseYN: e.target.value,
    }));
  };

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
    navigate(-1);
  }, []);

  //*  Mutation
  const { mutate } = useMutationHook("updateLayout");

  useEffect(() => {
    setIsLayout(true);

    const temp = Object.assign({}, defaultValues);

    for (const key in temp) {
      if (location.state[key] !== null) temp[key] = location.state[key];
      else delete temp[key];

      if (key === "layoutIdx") temp[key] = location.state.layoutIdx + "";
    }
    temp.frameColor = rgbaToObject(location.state.frameColor);
    if (temp.bgImageUseYN === "Y") {
      temp.defaultBgColor = "#FEFEFE";
      temp.defaultBgURL = location.state.bgColor;
    } else if (temp.bgImageUseYN === "N") {
      temp.defaultBgColor = location.state.bgColor;
      temp.defaultBgURL = "";
    }

    setSubmitValues(temp);
  }, [location]);

  //* Drawer Toggle
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setOpenDrawer(!open);
  };

  //* Layout 선택
  const handleSelectLayout = (e) =>
    setSubmitValues((prev) => ({
      ...prev,
      layoutIdx: e.target.name,
    }));

  //* REQUEST 전 DATA 정리
  const mergeData = () => {
    let input = {};

    input = submitValues;
    submitValues.bgImageUseYN === "N"
      ? (input.bgColor = submitValues.defaultBgColor)
      : (input.bgColor = submitValues.defaultBgURL);

    input.frameColor = objectToRgba(submitValues.frameColor);
    input.layoutIdx = Number(submitValues.layoutIdx);
    delete input.defaultBgColor;
    delete input.defaultBgURL;

    return input;
  };

  const handleSubmit = () => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPDATE_LAYOUT,
        title: "레이아웃 수정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          {
            companyIdx: location.state.companyIdx,
            submitValues: mergeData(),
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "레이아웃 수정이 완료되었습니다.",
                  title: "레이아웃 수정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("totalCompany");
              });
            },
          }
        );
      }
    });
  };

  const list = () => (
    <Box sx={{ width: 450, background: blueGrey[200] }} p={3} role="presentation">
      <Box position={"absolute"} bgcolor="white" p={2} top={20} borderRadius={"10px 10px 0px 0px"}>
        <Typography variant="h6" color="black">
          색상/로고 편집
        </Typography>
      </Box>
      <Stack direction={"row"} gap={2} justifyContent="flex-end">
        <Button variant="contained" onClick={handleSubmit}>
          저장하기
        </Button>

        <Button
          color="layoutBtn"
          variant="contained"
          onClick={() => {
            navigate(-1);
          }}
        >
          뒤로가기
        </Button>
      </Stack>
      <Box bgcolor="white" p={3} sx={{ borderRadius: "7px" }} my={2} height="auto">
        <Grid container gap={3}>
          <Grid container alignItems={"center"} justifyContent="space-between">
            <Title>검사이름</Title>
            <TextField
              size="small"
              helperText={"* 40자 이내 입력"}
              value={submitValues.titleText}
              onChange={handleTitle}
              FormHelperTextProps={{ sx: { margin: 0, height: 0 } }}
            />
            <ColorPickerBtn
              isFrame={false}
              colors={submitValues}
              setSubmitValues={setSubmitValues}
              isFullWidth={false}
              name="titleColor"
            />
          </Grid>
          <Grid container alignItems={"center"} justifyContent="space-between">
            <Grid container flexDirection={"column"}>
              <Grid item container justifyContent="space-between" alignItems={"center"}>
                <Grid item>
                  <Title>메인컬러</Title>
                </Grid>
                <Grid item xs={8}>
                  <ColorPickerBtn
                    isFrame={false}
                    colors={submitValues}
                    setSubmitValues={setSubmitValues}
                    isFullWidth={true}
                    name="mainColor"
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography color={"#777"} textAlign={"right"} variant={"body2"} sx={{ wordBreak: "keep-all" }}>
                  * 프로그램 내 상단바, 버튼 등의 위치에 포인트로 쓰이는 색상(주로 회사 대표 컬러 사용)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} justifyContent="space-between">
            <Grid container flexDirection={"column"}>
              <Grid item container justifyContent="space-between" alignItems={"center"}>
                <Grid item>
                  <Title>서브컬러</Title>
                </Grid>
                <Grid item xs={8}>
                  <ColorPickerBtn
                    isFrame={false}
                    colors={submitValues}
                    setSubmitValues={setSubmitValues}
                    isFullWidth={true}
                    name="subColor"
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography color={"#777"} textAlign={"right"} variant={"body2"} sx={{ wordBreak: "keep-all" }}>
                  * 검사 프로그램 내에서 보조적으로 쓰이는 색상 의미
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* <Divider /> */}
          <Divider sx={{ width: "100%" }} />

          <Grid container alignItems={"start"} justifyContent="space-between">
            <Grid item container alignItems={"center"} xs={4}>
              <Title>회사 로고 사용</Title>
              <Select size="small" fullWidth onChange={handleIsLogoUse} value={submitValues.ciUseYN}>
                <MenuItem key={"Y"} value={"Y"}>
                  예
                </MenuItem>
                <MenuItem key={"N"} value={"N"}>
                  아니오
                </MenuItem>
              </Select>
            </Grid>
            <Grid item container alignItems={"center"} xs={7}>
              <Title>회사 로고 업로드(png,jpg,svg)</Title>
              <UploadBtn info={submitValues} name="logo" setSubmitValues={setSubmitValues} />
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} justifyContent="space-between">
            <Grid item container alignItems={"center"} xs={5}>
              <Title>로그인 화면 버튼</Title>

              <ColorPickerBtn
                isFrame={false}
                colors={submitValues}
                setSubmitValues={setSubmitValues}
                isFullWidth={true}
                name="buttonColor"
              />
            </Grid>
            <Grid item container alignItems={"center"} xs={6}>
              <Title>로그인 프레임 컬러</Title>

              <ColorPickerBtn
                isFrame={true}
                colors={submitValues}
                setSubmitValues={setSubmitValues}
                isFullWidth={true}
                name="frameColor"
              />
            </Grid>
          </Grid>
          <Grid container alignItems={"start"} justifyContent="space-between">
            <FormControl>
              <RadioGroup
                row
                value={submitValues.bgImageUseYN}
                sx={{ justifyContent: "space-between" }}
                onChange={handleIsBgSelected}
              >
                <Grid item xs={5}>
                  <FormControlLabel value={"N"} control={<Radio size="small" />} label="배경색 선택" />

                  <ColorPickerBtn
                    isFrame={false}
                    colors={submitValues}
                    setSubmitValues={setSubmitValues}
                    isFullWidth={true}
                    name={"defaultBgColor"}
                    isBgImage={false}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <FormControlLabel value={"Y"} control={<Radio size="small" />} label="배경이미지 업로드" />
                  <UploadBtn info={submitValues} name="bgImage" setSubmitValues={setSubmitValues} />
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box bgcolor={"white"} width={"auto"} borderRadius={"10px 10px 0px 0px"} p={2}>
        <Typography variant="h6">레이아웃 선택</Typography>
      </Box>
      <Box bgcolor="white" p={3} sx={{ borderRadius: "0px 0px 7px 7px" }}>
        <Grid container columns={12} spacing={2}>
          {TEMPLATE_IMG.map((item, index) => {
            return (
              <Grid item xs={6} key={index}>
                <Typography variant="h7" fontWeight="bold">
                  {item.name}
                </Typography>
                <Card
                  variant="outlined"
                  sx={{
                    "&:hover": {
                      transition: "all .2s",
                      transform: "scale(1.2)",
                    },
                  }}
                >
                  <CardMedia component="img" name={index + 1} onClick={handleSelectLayout} image={item.src} />
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Main open={openDrawer}>
          <Box position="absolute" top={15} zIndex={100} right={openDrawer ? drawerWidth + 20 : 30}>
            <Button variant={"contained"} color="layoutEditBtn" disableElevation onClick={toggleDrawer(openDrawer)}>
              편집하기
            </Button>
          </Box>
          <LoginTemplate
            colors={submitValues}
            layoutNumInput={submitValues.layoutIdx}
            title={submitValues.titleText}
            bg={submitValues.bgImageUseYN === "N" ? submitValues.defaultBgColor : submitValues.defaultBgURL}
            bgImageUseYN={submitValues.bgImageUseYN}
            isLogoUse={submitValues.ciUseYN}
            ciFilePath={submitValues.ciFilePath}
          />
        </Main>
        <Drawer
          variant="persistent"
          hideBackdrop
          anchor={"right"}
          open={openDrawer}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          onClose={toggleDrawer("right", false)}
        >
          {list()}
        </Drawer>
      </Box>
    </>
  );
};

export default TemplateMain;
