import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { MESSAGE_REQUEST_AUTH_KEY } from "enums/modalMessage";
import useMutationHook from "hooks/useMutationHook";
import { useTimer } from "hooks/useTimer";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalAtom } from "recoil/store";
import { autoHyphen } from "utils/autoHyphen";

//* 핸드폰변경 인증번호 입력 컴포넌트
const VerifyCellNum = ({ onClose }) => {
  const [timerStart, setTimerStart] = useState(false);
  const [isShowInput, setIsShowInput] = useState(false);

  const { mutate } = useMutationHook("requestAuthKey", "HR");
  const { mutate: updateCellNum } = useMutationHook("updateCellNum", "HR");

  const count = useTimer(timerStart, 180);
  const [modalOn, setModalOn] = useRecoilState(modalAtom);

  const handleStopTimer = () => {
    setTimerStart(false);
  };

  const [userCell, setUserCell] = useState("");

  const handleChangeUserCell = (e) => {
    setUserCell(autoHyphen(e.target.value));
  };

  const handleRequest = () => {
    setModalOn({
      modalState: !modalOn.modalState,
      subTitle: MESSAGE_REQUEST_AUTH_KEY,
      type: "confirm",
      title: "응시자 등록",
      status: "warning",
      modalFunc: () => {
        mutate(
          { cell: userCell },
          {
            onSuccess: () => {
              setModalOn({
                modalState: !modalOn.modalState,
                title: "응시자 등록",
                type: "alert",
                subTitle: "요청 성공하였습니다. 인증번호를 확인해 주세요.",
                status: "success",
                modalFunc: () => {
                  setIsShowInput(!isShowInput);
                  setTimerStart(true);
                },
              });
            },
            onError: () => {
              setModalOn({
                modalState: !modalOn.modalState,
                title: "응시자 등록",
                type: "alert",
                subTitle: "요청 실패. 다시 시도해 주세요.",
                status: "success",
              });
            },
          }
        );
      },
    });
  };

  const authKeyRef = useRef();

  const handleUpdate = () => {
    updateCellNum(
      {
        authKey: authKeyRef.current.value,
        userCell: userCell,
      },
      {
        onSuccess: () => {
          setModalOn({
            modalState: !modalOn.modalState,
            title: "전화번호 변경",
            type: "alert",
            subTitle: "전화번호가 변경되었습니다.",
            status: "success",
          });
        },
        onError: (error) => {
          setModalOn({
            modalState: !modalOn.modalState,
            title: "전화번호 변경",
            type: "alert",
            subTitle: `전화번호 변경을 실패하였습니다.\n ${error.response.data.message}`,
            status: "error",
          });
        },
      }
    );
  };

  if (count < 1) {
    handleStopTimer();
  }

  return (
    <>
      <DialogTitle>핸드폰번호 변경</DialogTitle>
      <DialogContent>
        <Grid container flexDirection={"column"} rowGap={1}>
          <Typography>새 핸드폰번호를 입력하세요</Typography>
          <Grid item container columnGap={1} alignItems="stretch">
            <Grid item xs>
              <TextField
                fullWidth
                size="small"
                placeholder="000-0000-0000"
                value={userCell}
                onChange={handleChangeUserCell}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ height: "100%" }}
                onClick={handleRequest}
              >
                인증요청
              </Button>
            </Grid>
          </Grid>
          {isShowInput && (
            <Grid item container columnGap={1} alignItems="stretch">
              <Grid item xs>
                <TextField
                  fullWidth
                  size="small"
                  inputRef={authKeyRef}
                  placeholder="6자리 입력"
                  helperText={`${parseInt((count % 3600) / 60)}분 ${
                    count % 60
                  }초내 입력`}
                  FormHelperTextProps={{
                    sx: { margin: 0, height: 0, textAlign: "right" },
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ height: "100%" }}
                  onClick={handleUpdate}
                >
                  인증확인
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pr: 3, pb: 3 }}>
        <Button variant={"outlined"} onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </>
  );
};

export default VerifyCellNum;
