import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import styled from "@emotion/styled";

import { Outlet } from "react-router-dom";

import { companyInfoAtom, isLayoutPage, isNavOpen } from "recoil/store";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { jwtDecoder } from "utils/jwtDecoder";
import { motion } from "framer-motion";

import NavStats from "components/templates/nav/hr/NavStats";
import { useMediaQuery } from "@mui/material";

const MainContainer = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, islayout }) => ({
  flexGrow: 1,
  minWidth: 0,
  position: "relative",
  padding: islayout === "true" ? 0 : theme.spacing(2.3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-260px`,
  height: "100vh",
  background: islayout === "true" ? "white" : "#f8f9fa",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
export default function ContentStats() {
  const isOpen = useRecoilValue(isNavOpen);
  const isLayout = useRecoilValue(isLayoutPage);
  const companyInfo = useSetRecoilState(companyInfoAtom);

  useEffect(() => {
    companyInfo(jwtDecoder(localStorage.getItem("HR")));
  }, []);

  const setIsOpen = useSetRecoilState(isNavOpen);

  const matches = useMediaQuery("(max-width:500px)");
  const matchesMin = useMediaQuery("(min-width:800px)");

  useEffect(() => {
    if (matches) {
      setIsOpen(false);
    }

    if (matchesMin) {
      setIsOpen(true);
    }
  }, [matches, matchesMin]);

  // const userType = userTypeQs(location.pathname);

  return (
    <Box sx={{ display: "flex", background: "#f8f9fa" }}>
      <NavStats />
      <MainContainer open={isOpen} islayout={isLayout.toString()}>
        <motion.div
          style={{ height: "100%" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Outlet />
        </motion.div>
      </MainContainer>
    </Box>
  );
}
