import React, { useState } from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useParams } from "react-router-dom";

import useGetQueryhr from "hooks/useGetQueryhr";

import { tableHeader } from "themes/theme";
import { STATS_TESTER_LIST } from "enums/menuList";
import { calcRowNum } from "utils/calcRowNum";
import { examStatusTag } from "utils/tagRender";
const TestersList = () => {
  const { id } = useParams();
  const [paramValues, setParamValues] = useState({
    pageNo: 1,
    groupIdx: Number(id),
  });
  const { data, pageInfo, isLoading, isFetching, isError, refetch } =
    useGetQueryhr("getTesters_CJ", paramValues, true, false);

  return (
    <Box
      px={5}
      py={3.5}
      sx={{
        borderRadius: 2,
        background: "#ffffff",
        boxShadow: "7px 7px 14px #d1d1d1",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container justifyContent={"space-between"}>
        <Typography variant="h5" fontWeight={700}>
          응시자 목록
        </Typography>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Paper
        sx={{
          overflowX: "auto",
          height: "65%",
        }}
        variant={"outlined"}
      >
        <Table stickyHeader sx={tableHeader} size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="none" align="center" sx={{ width: 30 }}>
                <Checkbox
                  size="small"
                  style={{ color: "#637381" }}
                  //   onChange={handleSelectAllClick}
                />
              </TableCell>
              {STATS_TESTER_LIST.map((item) => {
                return (
                  <TableCell
                    padding="none"
                    key={item.label}
                    // sx={{ width: item.size, fontWeight: "bold" }}
                    align="center"
                  >
                    {item.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.data.map((item, index) => (
                <TableRow
                  sx={{
                    "&": { height: 45, cursor: "pointer" },
                    // "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  key={index}
                >
                  <TableCell padding="none" align="center">
                    <Checkbox
                      size="small"
                      color="primary"
                      sx={{ padding: 0 }}
                      //   onClick={(event) => handleCheckClick(event, row)}
                      //   checked={isSelected(row.groupIdx)}
                    />
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {calcRowNum(index, pageInfo, 10)}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {item.testerId}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {item.testerName}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {item.testerCell || "-"}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {examStatusTag(item.examStatus) || "-"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default TestersList;
