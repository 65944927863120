import React from "react";
import LOGO from "assets/image/errorPage/acgnetworkerror.svg";
import { Box, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
export default function ErrorPage() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      width={"100vw"}
      height={"100vh"}
    >
      <Box
        width={"1000px"}
        height="600px"
        display="flex"
        justifyContent={"center"}
        alignItems="center"
        bgcolor={"white"}
        boxShadow={"4px 4px 60px #ccc"}
        borderRadius={2}
        bottom={0}
        margin={"auto"}
        position="relative"
      >
        <Box component={"img"} src={LOGO} pb={7} pr={7} />
        <Box position={"absolute"} bottom={60}>
          <Link to="/login">
            <Button size="large" variant="contained">
              홈으로
            </Button>
          </Link>
        </Box>
        {/* </Box> */}
      </Box>
    </Grid>
  );
}
