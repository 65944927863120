import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import LOGO from "assets/image/logo/CJ.png";
import BACKGROUND from "assets/image/background/acg_bg.png";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useMutationHook from "hooks/useMutationHook";
import { useSetRecoilState } from "recoil";
import { HRcompanyInfo } from "recoil/store";
import { jwtDecoder } from "utils/jwtDecoder";
import { CircularProgress } from "@material-ui/core";
import { useTimer } from "hooks/useTimer";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { checkIcon, errorIcon } from "assets/icon/Icon_modal";
import Swal from "sweetalert2";
import { alertModalConfig } from "components/templates/modalConfig";

export default function LoginProgress() {
  const navigate = useNavigate();
  const location = useLocation();
  const companyInfoAtom = useSetRecoilState(HRcompanyInfo);

  //* cjworld
  const { mutate } = useMutationHook("cjworld_login", "HR");

  const [loginKey, setLoginKey] = useState(undefined);

  useEffect(() => {
    location &&
      setLoginKey(location.search.replace("?cjworld_id=", "").trimStart());
  }, [location]);

  const handleSuccessLogin = (res) => {
    const userInfo = jwtDecoder(res.data.data.accessToken);
    companyInfoAtom(userInfo);
    sessionStorage.setItem(userInfo.companyCode, res.data.data.accessToken);
    if (userInfo.userGradeIdx === 3)
      navigate(`/acghr_hr/${userInfo.companyCode}/stats`);
    else navigate(`/acghr_hr/${userInfo.companyCode}/group`);
  };

  const [isError, setIsError] = useState(false);

  const loginMutate = () => {
    if (loginKey === "") {
      setIsError(true);
      Swal.fire(
        alertModalConfig({
          text: "오류가 발생하였습니다.",
          title: "로그인",
          iconHtml: errorIcon,
          confirmButtonText: "확인",
        })
      );
    } else {
      mutate(
        { value: { cjworld_id: loginKey } },
        {
          onSuccess: (result) => {
            Swal.fire(
              alertModalConfig({
                text: "로그인되었습니다.",
                title: "로그인",
                iconHtml: checkIcon,
                confirmButtonText: "확인",
              })
            ).then((res) => {
              if (res.isConfirmed) {
                handleSuccessLogin(result);
              }
            });
          },
          onError: (error) => {
            setIsError(true);
          },
        }
      );
    }
  };

  const isMobile = useMediaQuery("(max-width:500px)");

  useEffect(() => {
    loginKey && loginMutate();
  }, [loginKey]);

  return (
    <Container maxWidth={false} disableGutters sx={bgContainer}>
      <Grid
        width={isMobile ? "25rem" : "30rem"}
        container
        p={4}
        justifyContent="center"
        bgcolor={"rgba( 255, 255, 255, 0.9 )"}
        boxShadow={"0 8px 32px 0 grey"}
        borderRadius={2}
        textAlign="center"
        border={"1px solid rgba( 255, 255, 255, 0.18 )"}
      >
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Box
              component="img"
              sx={{
                width: "8rem",
                maxWidth: { xs: "8rem", md: 250 },
              }}
              alt="LOGO_Login"
              src={LOGO}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={700}>
              HR 담당자 로그인
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {isError ? (
              <Grid
                container
                flexDirection={"column"}
                rowGap={1}
                alignItems="center"
              >
                <ErrorRoundedIcon color="error" fontSize="large" />
                <Typography fontWeight={700} color="error" variant="h6">
                  로그인 에러발생
                </Typography>
                <Button variant="contained" onClick={loginMutate}>
                  재시도
                </Button>
              </Grid>
            ) : (
              <>
                <CircularProgress size={20} />
                <Typography fontWeight={700}>
                  로그인 확인중 입니다...
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

const bgContainer = {
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: `url(${BACKGROUND})  no-repeat center`,
  backgroundSize: "cover",
};
