import { lazy, Suspense, useCallback, useState } from "react";

import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Trash2 } from "lucide-react";

import ErrorPage from "components/pages/error/networkError/ErrorPage";
import LoadingCircle from "components/UI/organism/LoadingCircle";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";

import { useQueryClient } from "@tanstack/react-query";
import useGetQueryhr from "hooks/useGetQueryhr";
import useMutationHook from "hooks/useMutationHook";

import { questionIcon } from "assets/icon/Icon_modal";
import { alertModalConfig } from "components/templates/modalConfig";
import TopTitle from "components/templates/topTitle/TopTitle";
import { HR_MANAGE_COL } from "enums/tableColumn";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { tableHeader, tableWrapper } from "themes/theme";
import { dateFormat } from "utils/formatter/dateFormat";

const DeleteManagerModal = lazy(() => import("./DeleteManagerModal"));
const SignUpEmailModal = lazy(() => import("components/pages/acg_admin/company/modules/userList/signUpEmailModal"));

export default function HRManage() {
  const [deleteManagerModalOn, setDeleteManagerModalOn] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState();
  const queryClient = useQueryClient();
  const [paramValues, setParamValues] = useState({
    pageNo: 1,
  });

  const { company } = useParams();

  //* Query
  const { data, pageInfo, isLoading, isFetching, isError } = useGetQueryhr("hrList", paramValues);

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  //* Mutation
  const { mutate } = useMutationHook("updateUserGrade", "HR");

  const [grade, setGrade] = useState();

  const [sendEmailModalOn, setSendEmailModalOn] = useState(false);

  const handleChangeGrade = (_, row) => {
    if (row.userGradeIdx === 1) setGrade("일반 담당자");
    else setGrade("메인 담당자");

    mutate(
      { userIdx: row.userIdx },
      {
        onSuccess: () => {
          Swal.fire(
            alertModalConfig({
              text: "권한 수정이 완료되었습니다.",
              title: "권한 수정",
              iconHtml: questionIcon,
              confirmButtonText: "확인",
            })
          ).then((result) => {
            if (result.isConfirmed) {
              alertResult("hrList");
            }
          });
        },
      }
    );
  };
  const handleSendEmailModal = () => setSendEmailModalOn(!sendEmailModalOn);

  //* DELETE MODAL
  const targetManager = (_, row) => setDeleteTarget(row.userIdx);
  const handleDeleteManagerModal = () => setDeleteManagerModalOn(!deleteManagerModalOn);

  // loginAvailable === 5이상 일때 로그인실패
  // loginAvailable === 6이상 일때  신입이..가입신청..?

  if (isError) return <ErrorPage />;
  return (
    <>
      <Box width="100%" height="100%" borderRadius={2} display="flex" flexDirection="column" rowGap={2}>
        <Grid container justifyContent={"space-between"} mb={1}>
          <TopTitle
            list={[
              {
                title: "HR 담당자 관리",
                url: `/acghr_hr/${company}/manager`,
                current: true,
              },
            ]}
          />
        </Grid>

        {isFetching || isLoading ? (
          <LoadingSkeleton />
        ) : (
          <Paper
            sx={{
              ...tableWrapper,
              overflowY: "hidden",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: 2,
              p: 2,
              rowGap: 2,
            }}
            variant="outlined"
          >
            <Grid container alignItems={"center"} justifyContent="space-between">
              <Grid item>
                <Typography fontSize={"13px"} fontWeight={700}>
                  총 {pageInfo.total}명
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" startIcon={<EmailRoundedIcon />} onClick={handleSendEmailModal}>
                  가입이메일 전송
                </Button>
              </Grid>
            </Grid>
            <TableContainer sx={{ height: "calc(95% - 20px)" }}>
              <Table stickyHeader sx={tableHeader} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="none" align="center" sx={{ width: 30 }}>
                      <Checkbox
                        size="small"
                        style={{ color: "#637381" }}
                        // onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    {HR_MANAGE_COL.map((item) => {
                      return (
                        <TableCell padding="none" key={item.label} align="center">
                          {item.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.data?.map((row) => (
                      <TableRow
                        hover
                        key={row.userIdx}
                        sx={{
                          "&": { height: 43 },
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <TableCell padding="none" align="center">
                          <Checkbox
                            size="small"
                            color="primary"
                            // onClick={(event) => handleCheckClick(event, row)}
                            // checked={isSelected(row.groupIdx)}
                          />
                        </TableCell>
                        {/* 유형 */}
                        {/* <TableCell padding="none" align="center">
                          <Select
                            name={"userGradeIdx"}
                            size="small"
                            value={
                              row.userGradeIdx === 2
                                ? "일반 담당자"
                                : "메인 담당자"
                            }
                            sx={{
                              "& > div": {
                                fontSize: "0.875rem",
                                p: 0.7,
                                background:
                                  row.userGradeIdx === 2
                                    ? "#FCD297"
                                    : "#FAA633",
                                color: "#fefefe",
                                fontWeight: 700,
                              },
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor:
                                  row.userGradeIdx === 2
                                    ? "#FCD297"
                                    : "#FAA633",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor:
                                    row.userGradeIdx === 2
                                      ? "#FCD297"
                                      : "#FAA633",
                                },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor:
                                  row.userGradeIdx === 2
                                    ? "#f9bd68"
                                    : "#ff9e17",
                              },
                            }}
                            onChange={(e) => handleChangeGrade(e, row)}
                          >
                            <MenuItem key={"메인 담당자"} value={"메인 담당자"}>
                              메인 담당자
                            </MenuItem>
                            <MenuItem key={"일반 담당자"} value={"일반 담당자"}>
                              일반 담당자
                            </MenuItem>
                          </Select>
                        </TableCell> */}
                        {/* 이름 */}
                        <TableCell padding="none" align="center">
                          {row.userName}
                        </TableCell>

                        {/* 휴대전화번호 */}
                        <TableCell padding="none" align="center">
                          {row.userCell}
                        </TableCell>

                        {/* 직통번호 */}
                        <TableCell padding="none" align="center">
                          {row.userTel}
                        </TableCell>
                        {/* 이메일 */}
                        <TableCell padding="none" align="center">
                          {row.userEmail}
                        </TableCell>

                        {/* 최근로그인시간 */}
                        <TableCell padding="none" align="center">
                          {dateFormat(row.updatedAt)}
                        </TableCell>
                        {/* 삭제 */}
                        <TableCell padding="none" align="center">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              targetManager(e, row);
                              handleDeleteManagerModal();
                            }}
                            sx={{
                              background: "#ffe8db",
                              color: "#f97c34",
                              "&>svg": {
                                width: "1.2rem",
                                height: "1.2rem",
                              },
                            }}
                          >
                            <Trash2 size={15} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container alignItems={"center"} justifyContent="center">
              <Pagination
                size="small"
                count={pageInfo.totalPage}
                page={pageInfo.pageNo}
                onChange={(_, page) => {
                  setParamValues((prev) => ({
                    ...prev,
                    pageNo: page,
                  }));
                }}
              />
            </Grid>
          </Paper>
        )}
      </Box>

      {/* 담당자 삭제하기 모달 */}
      <Dialog open={deleteManagerModalOn} onClose={handleDeleteManagerModal} maxWidth="xs" fullWidth>
        <Suspense fallback={<LoadingCircle />}>
          <DeleteManagerModal userIdx={deleteTarget} onClose={handleDeleteManagerModal} isHR={true} />
        </Suspense>
      </Dialog>

      {/* 가입 이메일 전송 모달 */}
      <Dialog open={sendEmailModalOn} onClose={handleSendEmailModal} maxWidth="xs" fullWidth>
        <Suspense fallback={<LoadingSkeleton />}>
          <SignUpEmailModal
            // companyIdx={companyIdx}
            fromHRmanager={true}
            onClose={handleSendEmailModal}
          />
        </Suspense>
      </Dialog>
    </>
  );
}
