import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { Collapse } from "@mui/material";
import { Link } from "react-router-dom";

const NavMenuList = ({ menuListTitle = [], directPath = "" }) => {
  const [menuIsOpen, setMenuIsOpen] = useState({
    0: true,
  });

  const handleClickMenu = (_, index) => {
    setMenuIsOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <>
      {menuListTitle.map((menu, index) => {
        return (
          <React.Fragment key={index}>
            <List>
              <ListItemButton onClick={(e) => handleClickMenu(e, index)}>
                <ListItemText
                  primary={menu.depth1Title}
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
                {menuIsOpen[index] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={menuIsOpen[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menu.sub.map((sub, index) => (
                    <Link to={sub.path} key={index} reloadDocument>
                      <ListItemButton sx={{ pl: 4 }} key={index}>
                        <ListItemIcon sx={{ minWidth: "36px" }}>
                          {sub.icon}
                        </ListItemIcon>
                        <ListItemText children={sub.depth2Title} />
                      </ListItemButton>
                    </Link>
                  ))}
                </List>
              </Collapse>
            </List>
            {index !== menuListTitle.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default NavMenuList;
