import { useMediaQuery } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { isNavOpen } from "recoil/store";
import { userTypeQs } from "utils/userTypeQs";
import NavHR from "./hr/NavHR";
import NavSuper from "./super/NavSuper";

const NavComponent = () => {
  const setIsOpen = useSetRecoilState(isNavOpen);

  const matches = useMediaQuery("(max-width:500px)");
  const matchesMin = useMediaQuery("(min-width:800px)");

  useEffect(() => {
    if (matches) {
      setIsOpen(false);
    }

    if (matchesMin) {
      setIsOpen(true);
    }
  }, [matches, matchesMin]);

  const location = useLocation();
  const userType = userTypeQs(location.pathname);

  return userType === "HR" ? <NavHR /> : <NavSuper />;
};

export default NavComponent;
