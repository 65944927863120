import React, { lazy, useState } from "react";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavOpenBtn from "components/templates/NavOpenBtn";
import { Box } from "@mui/material";
import UpdateBasicInfo from "./UpdateBasicInfo";
import UpdateCellNum from "./UpdateCellNum";
import UpdateUserPassword from "./UpdateUserPassword";

//* 개인정보 수정 부모 컴포넌트
const UpdateUser = () => {
  const navigate = useNavigate();
  const { company } = useParams(); //*RoomIdx

  const [menuSelect, setMenuSelect] = useState(1);

  const handleMenu = (_, index) => {
    setMenuSelect(index);
  };
  const switchMenu = (menuSelect) => {
    if (menuSelect === 1) return <UpdateBasicInfo />;
    else if (menuSelect === 2) return <UpdateCellNum />;
    else return <UpdateUserPassword />;
  };

  return (
    <Grid
      width="100%"
      height="100%"
      borderRadius={2}
      display="flex"
      flexDirection="column"
      rowGap={2}
    >
      <Grid container justifyContent={"space-between"} mb={1}>
        <Box display={"flex"} alignItems="center" gap={2}>
          <NavOpenBtn />
          <Breadcrumbs>
            <HomeRoundedIcon
              style={{ color: "#77777" }}
              sx={{ display: "flex", alignItems: "center" }}
              onClick={() => navigate(-1)}
            />
            <Link key={2}>
              <Typography variant="h5" fontWeight={700}>
                개인정보수정
              </Typography>
            </Link>
          </Breadcrumbs>
        </Box>
      </Grid>

      <Grid
        item
        container
        justifyContent={"flex-start"}
        bgcolor="white"
        height={"100%"}
        borderRadius={2}
        p={3}
      >
        <Grid container columnGap={7}>
          <Grid item xs={1.8}>
            <List>
              <ListItem disablePadding onClick={(e) => handleMenu(e, 1)}>
                <ListItemButton>
                  <ListItemText primary="▪️ 기본정보 수정" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={(e) => handleMenu(e, 2)}>
                <ListItemButton>
                  <ListItemText primary="▪️ 휴대전화 번호 수정" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={(e) => handleMenu(e, 3)}>
                <ListItemButton>
                  <ListItemText primary="▪️ 비밀번호 변경" />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item container flexDirection={"column"} maxWidth={400}>
            {switchMenu(menuSelect)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpdateUser;
